<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <div class="tabs_content">
                <!-- <button type="button" class="toggle_bar" @click="toggleSectionBar"><img src="@/assets/images/bar-left.svg" alt=""></button> -->
                <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" ref="habit-wizard-form" class="habit-wizard-form">
                    <div class="step_wpr content_area">
                        <div class="info_bar">
                            <!-- <div class="time">Estimated Time: <span>6 min.</span></div> -->
                            <div class="step_bar2">
                                <ul>
                                    <li @click="toggleStep(1)" :class="{ 'active' : habitStep == 1 || habitStep > 1 }">
                                        <span>1</span>
                                        <h6>Interval</h6>
                                    </li>
                                    <li @click="toggleStep(2);" :class="{ 'active' : habitStep == 2 || habitStep > 2}">
                                        <span>2</span>
                                        <h6>Habits</h6>
                                    </li>
                                    <li @click="toggleStep(3);" :class="{ 'active' : habitStep == 3 || habitStep > 3}">
                                        <span>3</span>
                                        <h6>Public Page</h6>
                                    </li>
                                    <li @click="toggleStep(4)" :class="{ 'active' : habitStep == 4 || habitStep > 4 }">
                                        <span>4</span>
                                        <h6>Notifications</h6>
                                    </li>
                                    <li @click="toggleStep(5)" :class="{ 'active' : habitStep == 5 || habitStep > 5}">
                                        <span>5</span>
                                        <h6>SEO</h6>
                                    </li>
                                </ul>
                                <div class="total_bar"><span :style="`width:${(100 / 4) * (habitStep - 1) }%`"></span></div>
                            </div>
                        </div>
                        <ul class="btn_list" v-if="habitStep == 3">
                            <li v-if="toggleAll" @click="openAllSection">Open All</li>
                            <li v-else @click="closeAllSection">Close All</li>
                        </ul>
                        <div class="content_wpr" v-if="habitStep == 1">
                            <div class="section_content w-100 open_area">
                                <div class="section_header">
                                    <h2>Scenario Name</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Scenario Name</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.name }">
                                                    <Field autocomplete="off" type="text" name="name" v-model="form.name" placeholder="Habit tracking name" rules="required" />
                                                </div>
                                                <ErrorMessage name="name" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Collection Interval</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <ul class="time_listing">
                                            <li>
                                                <label for="daily" class="checkbox">
                                                    <input type="radio" name="repeat" id="daily" value="2" v-model="form.recurring_type" hidden>
                                                    <div class="selection_box">Daily</div>
                                                </label>
                                            </li>
                                            <li>
                                                <label for="weekly" class="checkbox">
                                                    <input type="radio" name="repeat" id="weekly" value="3" v-model="form.recurring_type" hidden>
                                                    <div class="selection_box">Weekly</div>
                                                </label>
                                            </li>
                                        </ul>
                                        <div v-if="form.recurring_type == 2">
                                            <ul class="day_type">
                                                <li>
                                                    <div class="day_wpr">
                                                        <label for="every">
                                                            <input type="radio" id="every" name="day_type" value="d" v-model="form.recurring_settings.day_type" checked hidden>
                                                            <span><i class="fas fa-check"></i></span>
                                                        </label>
                                                        <h4>
                                                            Every <Field autocomplete="off" type="number" name="day_count" v-model="form.recurring_settings.days" placeholder="1" label="days" :class="{ 'has-error': errors.day_count }" :disabled="form.recurring_settings.day_type != 'd'" class="sqr_input" /> Day(s)
                                                        </h4>
                                                    </div>
                                                    <ErrorMessage name="day_count" class="text-danger" />
                                                </li>
                                                <li>
                                                    <div class="day_wpr">
                                                        <label for="work">
                                                            <input type="radio" id="work" name="day_type" value="w" v-model="form.recurring_settings.day_type" hidden>
                                                            <span><i class="fas fa-check"></i></span>
                                                        </label>
                                                        <h4>Every Work Day</h4>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div class="time_selection">
                                                <div class="form_grp m-0">
                                                    <div class="group_item">
                                                        <h3 class="sub_heading2 mb-0">At</h3>
                                                        <div class="field_wpr">
                                                            <multiselect v-model="form.recurring_settings.send_at" v-bind="hours"></multiselect>
                                                        </div>
                                                    </div>
                                                    <div class="group_item">
                                                        <div class="field_wpr">
                                                            <multiselect v-model="form.recurring_settings.send_at_type" v-bind="clock"></multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form_grp m-0">
                                                    <div class="group_item">
                                                        <label class="input_label">Timezone</label>
                                                        <div class="field_wpr">
                                                            <multiselect
                                                                v-model="form.recurring_settings.time_zone"
                                                                :options="timezones"
                                                                valueProp="timezone"
                                                                label="timezone"
                                                                :searchable="true"
                                                            >
                                                                <template v-slot:option="{ option }">
                                                                    <span>{{ `${ option.abbr} ${ option.utc_offset_str} ${ option.timezone}` }}</span>
                                                                </template>
                                                            </multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="form.recurring_type == 3" class="pt-2">
                                            <h3>Repeat Every <Field autocomplete="off" type="number" name="week_count" class="sqr_input" v-model="form.recurring_settings.weeks" :class="{ 'has-error': errors.week_count }" :rules="{ min_value: 1 }" /> Week(s)</h3>
                                            <ul class="days">
                                                <li v-for="(week, key) of weekdays" :key="key">
                                                    <label :for="`check-${key}`" :class="{'active' : form.recurring_settings.weekdays[week]}">
                                                        <span class="weekdays capitalize">{{ week }}</span>
                                                        <input type="checkbox" :id="`check-${key}`" v-model="form.recurring_settings.weekdays[week]" :value="true" @change="handleWeekdaysChange" :data-day="week" hidden>
                                                        <span class="tick_box"></span>
                                                    </label>
                                                </li>
                                            </ul>
                                            <div class="time_selection">
                                                <div class="form_grp m-0">
                                                    <div class="group_item">
                                                        <label class="input_label">At</label>
                                                        <div class="field_wpr">
                                                            <multiselect v-model="form.recurring_settings.send_at" v-bind="hours"></multiselect>
                                                        </div>
                                                    </div>
                                                    <div class="group_item">
                                                        <div class="field_wpr">
                                                            <multiselect v-model="form.recurring_settings.send_at_type" v-bind="clock"></multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form_grp m-0">
                                                    <div class="group_item">
                                                        <label class="input_label">Timezone</label>
                                                        <div class="field_wpr">
                                                            <multiselect
                                                                v-model="form.recurring_settings.time_zone"
                                                                :options="timezones"
                                                                valueProp="timezone"
                                                                label="timezone"
                                                                :searchable="true"
                                                            >
                                                                <template v-slot:option="{ option }">
                                                                    <span>{{ `${ option.abbr} ${ option.utc_offset_str} ${ option.timezone}` }}</span>
                                                                </template>
                                                            </multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h3 class="sub_heading2 mt-3 mb-0 p-0">Until</h3>
                                        <ul class="day_type">
                                            <li>
                                                <div class="day_wpr">
                                                    <label for="ongoing">
                                                        <input type="radio" id="ongoing" value="1" v-model="form.recurring_settings.end_type" hidden>
                                                        <span><i class="fas fa-check"></i></span>
                                                    </label>
                                                    <h4>Ongoing</h4>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="day_wpr">
                                                    <label for="after">
                                                        <input type="radio" id="after" value="2" v-model="form.recurring_settings.end_type" hidden>
                                                        <span><i class="fas fa-check"></i></span>
                                                    </label>
                                                    <h4>After <Field autocomplete="off" type="number" name="end_occurrences" v-model="form.recurring_settings.end_occurrences" placeholder="1" rules="numeric" label="occurrences" :class="{ 'has-error': errors.end_occurrences }" :disabled="form.recurring_settings.end_type != 2" class="sqr_input" /> Occurrences</h4>
                                                </div>
                                                <ErrorMessage name="end_occurrences" class="text-danger" />
                                            </li>
                                            <li>
                                                <div class="day_wpr">
                                                    <label for="end">
                                                        <input type="radio" id="end" value="3" v-model="form.recurring_settings.end_type" hidden>
                                                        <span><i class="fas fa-check"></i></span>
                                                    </label>
                                                    <h4>End On <datepicker  v-model="form.recurring_settings.end_date" :min-date="new Date()" autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy" :enableTimePicker="false" :disabled="form.recurring_settings.end_type != 3"></datepicker></h4>
                                                </div>
                                            </li>
                                        </ul>
                                        <div class="setting_wpr mt-2" v-if="form.recurring_settings.end_type == 1">
                                            <div class="capsule_elm">
                                                <h3 class="sub_heading2 m-0">Limited Submissions</h3>
                                                <label for="limited_submission" class="switch_option capsule_btn">
                                                    <input type="checkbox" id="limited_submission" :true-value="1" :false-value="0" v-model="form.data_settings.limited_submission" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div class="form_grp" v-if="form.data_settings.limited_submission">
                                                <div class="group_item">
                                                    <label class="input_label">Allowable Submissions</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.total_submission }">
                                                        <Field autocomplete="off" type="number" name="total_submission" v-model="form.data_settings.total_submission" placeholder="1" rules="numeric" label="allowable submissions" />
                                                    </div>
                                                    <ErrorMessage name="total_submission" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="setting_wpr mt-2" v-if="form.recurring_type == 3">
                                            <div class="capsule_elm">
                                                <h3 class="sub_heading2 m-0">{{form.is_cumulative ? 'Cumulative Model' : 'Pass/Fail Model'}}</h3>
                                                <label for="cumulative_model" class="switch_option capsule_btn">
                                                    <input type="checkbox" id="cumulative_model" :true-value="1" :false-value="0" v-model="form.is_cumulative" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="setting_wpr" v-if="form.recurring_settings.end_type == 2 || form.recurring_settings.end_type == 3">
                                            <ul class="day_type">
                                                <li>
                                                    <div class="day_wpr">
                                                        <label for="ongoing_score">
                                                            <input type="radio" id="ongoing_score" value="0" v-model="form.calculation_type" hidden>
                                                            <span><i class="fas fa-check"></i></span>
                                                        </label>
                                                        <h4>Participation scored as ongoing </h4>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="day_wpr">
                                                        <label for="whole_score">
                                                            <input type="radio" id="whole_score" value="1" v-model="form.calculation_type" hidden>
                                                            <span><i class="fas fa-check"></i></span>
                                                        </label>
                                                        <h4>Participation scored against end date</h4>
                                                    </div>
                                                    <ErrorMessage name="end_occurrences" class="text-danger" />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="habitStep == 2">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Categories</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <button type="button" class="add_btn ml-auto mt-2" @click="handleAddCategory()"><i class="fas fa-plus"></i> Add Category</button>
                                        <draggable v-model="habitTrackingCategories" handle=".handle-cat-drag" tag="ul" item-key="id" class="custom_list mt-3" :animation="200" @end="handleCategorySort">
                                            <template #item="{element, index}">
                                                <div>
                                                    <li class="move" :id="`category-box-${index}`">
                                                        <h5 class="category_bar" :class="{'active' : element.id == editedCategory}">
                                                            <div class="bar_text">
                                                                <i class="fas fa-arrows-alt mr-2 handle-cat-drag"></i>
                                                                <Field autocomplete="off" type="text" :name="`category_${element.id}`" v-model="element.name" :readonly="editedCategory != element.id" @click="editedCategory = element.id" label="category" />
                                                                <span v-if="element.id == editedCategory" class="save" @click="updateHabitCategory(element, index)">Save</span>
                                                            </div>
                                                            <div class="bar_actions">
                                                                <label class="pointer" :class="{'has' : element.habits.length}" @click="handleHabitsToggle($event)">
                                                                    <i class="fa fa-chevron-down mr-1"></i>{{element.habits.length}} Habits
                                                                </label>
                                                                <button :disabled="addHabitLoader.includes(element.id)" type="button" class="add_btn" @click="handleAddHabit(element.id, index)">
                                                                    <span v-if="addHabitLoader.includes(element.id)">
                                                                        <i class="fa fa-spinner fa-spin"></i>Adding Habit
                                                                    </span>
                                                                    <span v-else><i class="fas fa-plus"></i>Add Habit</span>
                                                                </button>
                                                                <button :disabled="addHabitLoader.includes(element.id)" type="button" class="delete_btn" @click="handleCategoryDelete(element.id, index)">
                                                                    <i class="fas fa-trash"></i>
                                                                </button>
                                                            </div>
                                                        </h5>
                                                        <draggable v-model="element.habits" handle=".handle-habit-drag" tag="div" class="sub_categories" item-key="id" :animation="200" @end="handleCategoryHabitSort(index)" v-if="element.habits.length">
                                                            <template #item="{element: habit, index: h}">
                                                                <div class="habit_item">
                                                                    <h5 class="category_bar" :class="{'active' : habit.id == editedCategory}">
                                                                        <div class="bar_text">
                                                                            <i class="fas fa-arrows-alt mr-2 handle-habit-drag"></i>
                                                                            <Field autocomplete="off" type="text" :name="`habit_name_${habit.id}`" v-model="habit.name" :readonly="editedCategory != habit.id" @click="editedCategory = habit.id" label="habit name" />
                                                                            <span v-if="habit.id == editedCategory" class="save" @click="handleCategoryHabitAutosave()">Save</span>
                                                                        </div>
                                                                        <div class="bar_actions">
                                                                            <label class="pointer has" v-if="editedHabitId == habit.id" @click="handleHabitsEdit($event, 0)">
                                                                                Close
                                                                            </label>
                                                                            <label class="pointer has" v-else @click="handleHabitsEdit($event, habit.id)">
                                                                                Open
                                                                            </label>
                                                                            <button type="button" class="delete_btn" @click="handleDeleteCategoryHabit(index, h, habit.id)"><i class="fas fa-trash"></i></button>
                                                                        </div>
                                                                    </h5>
                                                                    <div class="habit_edit">
                                                                        <div class="form_grp">
                                                                            <div class="group_item">
                                                                                <label class="input_label">Habit Text</label>
                                                                                <div class="field_wpr" :class="{ 'has-error': errors[`habit_description_${habit.id}`] }">
                                                                                    <Field autocomplete="off" type="text" @focusout="handleCategoryHabitAutosave()" :name="`habit_description_${habit.id}`" v-model="habit.description" placeholder="Habit text goes here..." />
                                                                                </div>
                                                                                <ErrorMessage :name="`habit_description_${habit.id}`" class="text-danger" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="form_grp">
                                                                            <div class="group_item">
                                                                                <label class="input_label">Requirements</label>
                                                                                <div class="field_wpr" :class="{ 'has-error': errors[`habit_requirements_${habit.id}`] }">
                                                                                    <Field autocomplete="off" type="textarea" :name="`habit_requirements_${habit.id}`" v-model="habit.requirements">
                                                                                        <textarea cols="10" @focusout="handleCategoryHabitAutosave()" rows="10" placeholder="Description Goes Here.." v-model="habit.requirements"></textarea>
                                                                                    </Field>
                                                                                </div>
                                                                                <ErrorMessage :name="`habit_requirements_${habit.id}`" class="text-danger" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="form_grp" v-if="form.recurring_type == 3">
                                                                            <div class="group_item">
                                                                                <label class="input_label">Success Goal</label>
                                                                                <div class="counter_fld">
                                                                                    <i class="fas fa-minus" @click="handleSuccessGoalIncrementDecrement(habit, '-')"></i>
                                                                                    <Field autocomplete="off" class="sqr_input" @change="handleCategoryHabitAutosave()" type="number" :name="`habit_success_goal_${habit.id}`" v-model="habit.success_goal" placeholder="Success Goal" rules="numeric|min:1" />
                                                                                    <i class="fas fa-plus" @click="handleSuccessGoalIncrementDecrement(habit, '+')"></i>
                                                                                    <p>Days Per Week</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="capsule_elm">
                                                                            <h5>Submit Proof?</h5>
                                                                            <label :for="`submit-proof-${habit.id}-${h}`" class="switch_option capsule_btn">
                                                                                <input type="checkbox" :id="`submit-proof-${habit.id}-${h}`" @change="handleCategoryHabitAutosave()" :true-value="1" :false-value="0" v-model="habit.submit_proof" hidden>
                                                                                <div><span></span></div>
                                                                            </label>
                                                                        </div>
                                                                        <div class="action_wpr">
                                                                            <button type="button" class="btn danger_btn" @click="handleDeleteCategoryHabit(index, h, habit.id)">Delete</button>
                                                                            <button type="button" class="btn save_btn"  @click="categoryHabitPreview = true; selectedCategoryHabit = habit">Preview</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </draggable>
                                                        <div class="sub_categories" v-else>
                                                            <h6>No habit found</h6>
                                                        </div>
                                                    </li>
                                                    <ErrorMessage :name="`category_${element.id}`" class="text-danger category-error" as="div" />
                                                </div>
                                            </template>
                                        </draggable>
                                    </div>
                                </div>
                                <div class="color_container mt-5">
                                    <div class="capsule_elm">
                                        <h5>Show Public Questions?</h5>
                                        <label for="public_questions" class="switch_option capsule_btn">
                                            <input type="checkbox" id="public_questions" :true-value="1" :false-value="0" v-model="form.data_settings.collapse_public_questions" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="habitStep == 3">
                            <div class="section_content w-100 open_area">
                                <div class="section_header">
                                    <h2>Public Page</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp p-0 mt-1 mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Public Url</label>
                                                <div class="field_wpr has_suffix">
                                                    <Field autocomplete="off" type="text" v-model="form.url" name="url" placeholder="https://superfitcoaching.com" />
                                                    <span class="suffix pointer" @click="handleCopy(form.url)">Copy</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="capsule_elm pt-2">
                                            <h5>Apply Saved Styling?</h5>
                                            <label for="apply_saved_styling" class="switch_option capsule_btn border-0">
                                                <input type="checkbox" id="apply_saved_styling" :true-value="1" :false-value="0" v-model="form.apply_saved_styling" @change="handleApplySavedStylingWarning()" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" ref="hdrsetting"  @click="goTo('hdrsection')">
                                <div class="section_header">
                                    <h2>Header</h2>
                                    <span class="status" :style="`color: ${form.public_settings.display_header ? '#2f7eed' : '#999'};`">{{ form.public_settings.display_header ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="capsule_elm">
                                            <h5>Show</h5>
                                            <label for="display_header" class="switch_option capsule_btn">
                                                <input type="checkbox" id="display_header" :true-value="1" :false-value="0" v-model="form.public_settings.display_header" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <Transition duration="550" name="nested">
                                            <div class="setting_wpr" v-show="form.public_settings.display_header">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Company Name <a class="reset-default" @click="form.public_settings.company_branding = user.businessname">Reset to default</a></label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.company_branding }">
                                                            <Field autocomplete="off" type="text" name="company_branding" v-model="form.public_settings.company_branding" placeholder="Company Name" />
                                                        </div>
                                                        <ErrorMessage name="company_branding" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Header Background Color <a class="reset-default" @click="form.public_settings.header_bgcolor = defaultHabitTracking.public_settings.header_bgcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="header_bgcolor" type="text" label="header background color">
                                                            <color-picker v-model="form.public_settings.header_bgcolor"/>
                                                        </Field>
                                                        <ErrorMessage name="header_bgcolor" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Header Text Color <a class="reset-default" @click="form.public_settings.header_textcolor = defaultHabitTracking.public_settings.header_textcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="header_textcolor" type="text" label="header text color">
                                                            <color-picker v-model="form.public_settings.header_textcolor"/>
                                                        </Field>
                                                        <ErrorMessage name="header_textcolor" class="text-danger" />
                                                    </div>
                                                </div>
                                                <ul class="tab_sec mt-2">
                                                    <li @click="headerTab = 'logo'" :class="{ active: headerTab === 'logo' }">Header Logo</li>
                                                    <li @click="headerTab = 'author'" :class="{ active: headerTab === 'author' }">Header Author Image</li>
                                                </ul>
                                                <div class="setting_wpr">
                                                    <div v-if="headerTab === 'logo'">
                                                        <div class="capsule_elm">
                                                            <h5>Display Logo</h5>
                                                            <label for="show_logo" class="switch_option capsule_btn">
                                                                <input type="checkbox" id="show_logo" :true-value="1" :false-value="0" v-model="form.public_settings.display_logo" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <div v-if="form.public_settings.display_logo">
                                                            <image-library image-type="public-logo" upload-text="Logo" v-model="form.public_settings.logo" />
                                                        </div>
                                                    </div>
                                                    <div v-if="headerTab === 'author'">
                                                        <div class="capsule_elm">
                                                            <h5>Display Author</h5>
                                                            <label for="coach_img" class="switch_option capsule_btn">
                                                                <input type="checkbox" id="coach_img" :true-value="1" :false-value="0" v-model="form.public_settings.display_author" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <div v-if="form.public_settings.display_author">
                                                            <image-library image-type="avatar" :is-avatar="true" upload-text="Image" v-model="form.public_settings.author" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" ref="cvrsetting"  @click="goTo('cvrsection')">
                                <div class="section_header">
                                    <h2>Cover</h2>
                                    <span class="status" :style="`color: ${form.public_settings.display_cover ? '#2f7eed' : '#999'};`">{{ form.public_settings.display_cover ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1 mb-2">
                                        <div class="capsule_elm">
                                            <h5>Show <span v-if="!form.public_settings.small_cover">Upload a small screen cover for a better view</span></h5>
                                            <label for="cover" class="switch_option capsule_btn">
                                                <input type="checkbox" id="cover" :true-value="1" :false-value="0" v-model="form.public_settings.display_cover" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <Transition duration="550" name="nested">
                                            <div v-if="form.public_settings.display_cover">
                                                <div class="cover_type">
                                                    <ul class="type_list">
                                                        <li>
                                                            <label for="clr_text">
                                                                <input type="radio" id="clr_text" :value="1" v-model="form.public_settings.cover_type" hidden>
                                                                <img src="@/assets/images/background.svg" alt="">
                                                                <h5>Color + Text</h5>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="img_text">
                                                                <input type="radio" id="img_text" :value="2" v-model="form.public_settings.cover_type" hidden>
                                                                <img src="@/assets/images/picture.svg" alt="">
                                                                <h5>Image + Text</h5>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="img_only">
                                                                <input type="radio" id="img_only" :value="3" v-model="form.public_settings.cover_type" hidden>
                                                                <img src="@/assets/images/picture.svg" alt="">
                                                                <h5>Image Only</h5>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="setting_wpr" v-if="form.public_settings.cover_type !== 3">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">
                                                                Headline
                                                                <div class="flex-center">
                                                                    <button type="button" class="reset-default" @click="form.public_settings.headline = defaultHabitTracking.public_settings.headline">Reset to default</button>
                                                                    <button type="button" class="pointer px-1" @click="headlineSetting = !headlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                                </div>
                                                            </label>
                                                            <div class="field_wpr" :class="{ 'has-error': errors.headline }">
                                                                <Field autocomplete="off" type="text" name="headline" v-model="form.public_settings.headline" placeholder="Compelling Headline Goes Here" />
                                                            </div>
                                                            <ErrorMessage name="headline" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div v-if="headlineSetting">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Family <a class="reset-default" @click="form.headline_setting.font_family = defaultHabitTracking.headline_setting.font_family">Reset to default</a></label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.headline_setting.font_family" v-bind="fontFamily"></multiselect>
                                                                </div>
                                                            </div>
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Weight <a class="reset-default" @click="form.headline_setting.font_weight = defaultHabitTracking.headline_setting.font_weight">Reset to default</a></label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.headline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Size <a class="reset-default" @click="form.headline_setting.font_size = defaultHabitTracking.headline_setting.font_size">Reset to default</a></label>
                                                                <div class="field_wpr m-0">
                                                                    <Field autocomplete="nofill" type="number" name="headline_setting_font_size" v-model="form.headline_setting.font_size" min="0" max="100" placeholder="90" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Color <a class="reset-default" @click="form.headline_setting.font_color = defaultHabitTracking.headline_setting.font_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="headline_setting_cover_textcolor" v-model="form.headline_setting.font_color" type="text" label="element color">
                                                                    <color-picker v-model="form.headline_setting.font_color" :classes="{ 'has-error': errors.headline_setting_cover_textcolor }" />
                                                                </Field>
                                                                <ErrorMessage name="headline_setting_cover_textcolor" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="mt-2 mb-4">
                                                            <div class="capsule_elm">
                                                                <h5>Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                                <label for="headline_shadow" class="switch_option capsule_btn">
                                                                    <input type="checkbox" id="headline_shadow" :true-value="1" :false-value="0" v-model="form.headline_setting.has_shadow" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                            <div v-if="form.headline_setting.has_shadow">
                                                                <div class="form_grp">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Offset-x <a class="reset-default" @click="form.headline_setting.shadow_x = defaultHabitTracking.headline_setting.shadow_x">Reset to default</a></label>
                                                                        <div class="field_wpr">
                                                                            <Field autocomplete="nofill" type="number" name="headline_setting_shadow_x" v-model="form.headline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="group_item">
                                                                        <label class="input_label">Offset-y <a class="reset-default" @click="form.headline_setting.shadow_y = defaultHabitTracking.headline_setting.shadow_y">Reset to default</a></label>
                                                                        <div class="field_wpr ">
                                                                            <Field autocomplete="nofill" type="number" name="headline_setting_shadow_y" v-model="form.headline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Blur-Radius <a class="reset-default" @click="form.headline_setting.shadow_blur = defaultHabitTracking.headline_setting.shadow_blur">Reset to default</a></label>
                                                                        <div class="field_wpr">
                                                                            <Field autocomplete="nofill" type="number" name="headline_setting_shadow_blur" v-model="form.headline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Shadow Color <a class="reset-default" @click="form.headline_setting.shadow_color = defaultHabitTracking.headline_setting.shadow_color">Reset to default</a></label>
                                                                        <Field autocomplete="off" name="headline_setting_shadow_color" v-model="form.headline_setting.shadow_color" type="text" label="element color">
                                                                            <color-picker v-model="form.headline_setting.shadow_color" :classes="{ 'has-error': errors.headline_setting_shadow_color }" />
                                                                        </Field>
                                                                        <ErrorMessage name="headline_setting_shadow_color" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr" v-if="form.public_settings.cover_type !== 3">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">
                                                                Sub Headline
                                                                <div class="flex-center">
                                                                    <button type="button" class="reset-default" @click="form.public_settings.subheadline = defaultHabitTracking.public_settings.subheadline">Reset to default</button>
                                                                    <button type="button" class="pointer px-1" @click="subheadlineSetting = !subheadlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                                </div>
                                                            </label>
                                                            <div class="field_wpr" :class="{ 'has-error': errors.sub_headline }">
                                                                <Field autocomplete="off" type="text" name="sub_headline" v-model="form.public_settings.subheadline" placeholder="Enter text here" />
                                                            </div>
                                                            <ErrorMessage name="sub_headline" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div v-if="subheadlineSetting">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Family <a class="reset-default" @click="form.subheadline_setting.font_family = defaultHabitTracking.subheadline_setting.font_family">Reset to default</a></label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.subheadline_setting.font_family" v-bind="fontFamily"></multiselect>
                                                                </div>
                                                            </div>
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Weight <a class="reset-default" @click="form.subheadline_setting.font_weight = defaultHabitTracking.subheadline_setting.font_weight">Reset to default</a></label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.subheadline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Size <a class="reset-default" @click="form.subheadline_setting.font_size = defaultHabitTracking.subheadline_setting.font_size">Reset to default</a></label>
                                                                <div class="field_wpr m-0">
                                                                    <Field autocomplete="nofill" type="number" name="subheadline_setting_font_size" v-model="form.subheadline_setting.font_size" min="0" max="100" placeholder="90" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Color <a class="reset-default" @click="form.subheadline_setting.font_color = defaultHabitTracking.subheadline_setting.font_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="subheadline_setting_font_color" v-model="form.subheadline_setting.font_color" type="text" label="element color">
                                                                    <color-picker v-model="form.subheadline_setting.font_color" :classes="{ 'has-error': errors.subheadline_setting_font_color }" />
                                                                </Field>
                                                                <ErrorMessage name="subheadline_setting_font_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="mt-2 mb-4">
                                                            <div class="capsule_elm">
                                                                <h5>Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                                <label for="subheadline_shadow" class="switch_option capsule_btn">
                                                                    <input type="checkbox" id="subheadline_shadow" :true-value="1" :false-value="0" v-model="form.subheadline_setting.has_shadow" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                            </div>
                                                            <div v-if="form.subheadline_setting.has_shadow">
                                                                <div class="form_grp">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Offset-x <a class="reset-default" @click="form.subheadline_setting.shadow_x = defaultHabitTracking.subheadline_setting.shadow_x">Reset to default</a></label>
                                                                        <div class="field_wpr">
                                                                            <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_x" v-model="form.subheadline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="group_item">
                                                                        <label class="input_label">Offset-y <a class="reset-default" @click="form.subheadline_setting.shadow_y = defaultHabitTracking.subheadline_setting.shadow_y">Reset to default</a></label>
                                                                        <div class="field_wpr ">
                                                                            <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_y" v-model="form.subheadline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-3">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Blur-Radius <a class="reset-default" @click="form.subheadline_setting.shadow_blur = defaultHabitTracking.subheadline_setting.shadow_blur">Reset to default</a></label>
                                                                        <div class="field_wpr">
                                                                            <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_blur" v-model="form.subheadline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-2">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Shadow Color <a class="reset-default" @click="form.subheadline_setting.shadow_color = defaultHabitTracking.subheadline_setting.shadow_color">Reset to default</a></label>
                                                                        <Field autocomplete="off" name="subheadline_setting_shadow_color" v-model="form.subheadline_setting.shadow_color" type="text" label="element color">
                                                                            <color-picker v-model="form.subheadline_setting.shadow_color" :classes="{ 'has-error': errors.subheadline_setting_shadow_color }" />
                                                                        </Field>
                                                                        <ErrorMessage name="subheadline_setting_shadow_color" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr" v-if="form.public_settings.cover_type !== 1">
                                                    <h3 class="sub_header mt-1">Cover Image</h3>
                                                    <image-library image-type="public-cover" upload-text="Cover" v-model="form.public_settings.cover" />
                                                    <div class="border-bottom pb-2 mb-2">
                                                        <div class="capsule_elm">
                                                            <h5>Small Screen Image</h5>
                                                            <label for="small_image" class="switch_option capsule_btn">
                                                                <input type="checkbox" id="small_image" :true-value="1" :false-value="0" v-model="form.public_settings.has_small_cover" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                        <image-library v-if="form.public_settings.has_small_cover" image-type="public-cover-small" upload-text="Cover" v-model="form.public_settings.small_cover" />
                                                    </div>
                                                    <div class="capsule_elm">
                                                        <h5>Add Image Overlay</h5>
                                                        <label for="overlay" class="switch_option capsule_btn">
                                                            <input type="checkbox" id="overlay" :true-value="1" :false-value="0" v-model="form.public_settings.has_cover_overlay" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                    <div v-if="form.public_settings.has_cover_overlay" class="setting_wpr mt-2">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Overlay Color <a class="reset-default"  @click="form.public_settings.overlay_color = defaultHabitTracking.public_settings.overlay_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="overlay_color" v-model="form.public_settings.overlay_color" type="text" label="element color">
                                                                    <color-picker v-model="form.public_settings.overlay_color" />
                                                                </Field>
                                                                <ErrorMessage name="overlay_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Overlay opacity <a class="reset-default" @click="form.public_settings.overlay_opacity = defaultHabitTracking.public_settings.overlay_opacity">Reset to default</a></label>
                                                                <div class="field_wpr" :class="{ 'has-error': errors.overlay_opacity }">
                                                                    <Field autocomplete="off" type="number" name="overlay_opacity" v-model="form.public_settings.overlay_opacity" min="0" max="100" rules="min:0|max:100" placeholder="68" />
                                                                </div>
                                                                <ErrorMessage name="overlay_opacity" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr" v-if="form.public_settings.cover_type === 1">
                                                    <div class="group_item">
                                                        <label class="input_label">Cover Background <a class="reset-default" @click="form.public_settings.cover_color = defaultHabitTracking.public_settings.cover_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="cover_background_color" v-model="form.public_settings.cover_color" type="text" label="element color">
                                                            <color-picker v-model="form.public_settings.cover_color" />
                                                        </Field>
                                                        <ErrorMessage name="cover_background_color" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header" @click="goTo('expvidsection')">
                                    <h2>Page Explainer Video</h2>
                                    <span class="status" :style="`color: ${form.public_settings.display_explainer_video ? '#2f7eed' : '#999'};`">{{ form.public_settings.display_explainer_video ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="capsule_elm">
                                            <h5>Show</h5>
                                            <label for="explain_video" class="switch_option capsule_btn">
                                                <input type="checkbox" id="explain_video" :true-value="1" :false-value="0" v-model="form.public_settings.display_explainer_video" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <Transition duration="550" name="nested">
                                            <div class="setting_wpr mt-3" v-show="form.public_settings.display_explainer_video">
                                                <div class="border-bottom pb-2">
                                                    <div class="capsule_elm">
                                                        <h5>Video Link (youtube, vimeo, wistia, screencast-o-matic, loom)</h5>
                                                        <label for="video_url" class="switch_option capsule_btn">
                                                            <input type="radio" id="video_url" value="0" v-model="form.public_settings.explainer_video_type" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                    <div class="setting_wpr mb-2" v-if="form.public_settings.explainer_video_type == 0">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.explainer_video }">
                                                                    <Field autocomplete="off" type="text" name="explainer_video" v-model="form.public_settings.explainer_video" placeholder="Video Link Goes Here..." rules="url" label="explainer video" />
                                                                    <span class="prefix">URL</span>
                                                                </div>
                                                                <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                                <ErrorMessage name="explainer_video" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="py-2">
                                                    <div class="capsule_elm">
                                                        <h5>Embeded Code (youtube, vimeo, wistia, screencast-o-matic, loom)</h5>
                                                        <label for="embed_video" class="switch_option capsule_btn">
                                                            <input type="radio" id="embed_video" value="1" v-model="form.public_settings.explainer_video_type" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                    <div class="setting_wpr" v-if="form.public_settings.explainer_video_type == 1">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.explainer_embed }">
                                                                    <Field autocomplete="off" type="text" name="explainer_embed" v-model="form.public_settings.explainer_embed" label="explainer embed code" :class="{ 'has-error': errors.explainer_embed }">
                                                                        <textarea cols="30" rows="10" v-model="form.public_settings.explainer_embed" placeholder="Embed code goes here.."></textarea>
                                                                    </Field>
                                                                </div>
                                                                <ErrorMessage name="explainer_embed" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Color</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Accent Color <a class="reset-default"  @click="form.public_settings.element_color = defaultHabitTracking.public_settings.element_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="element_color" type="text" label="element color">
                                                        <color-picker v-model="form.public_settings.element_color" />
                                                    </Field>
                                                    <ErrorMessage name="element_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Text Color <a class="reset-default"  @click="form.public_settings.element_text_color = defaultHabitTracking.public_settings.element_text_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="element_text_color" type="text" label="element text color">
                                                        <color-picker v-model="form.public_settings.element_text_color" />
                                                    </Field>
                                                    <ErrorMessage name="element_text_color" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Submit Button</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div ref="subbtnsetting" @click="goTo('subbtnsection')">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Submit Button Text <a class="reset-default" @click="form.public_settings.submit_btn_text = defaultHabitTracking.public_settings.submit_btn_text">Reset to default</a></label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.name }">
                                                            <Field autocomplete="off" type="text" name="name" v-model="form.public_settings.submit_btn_text" placeholder="ex: Submit Progress" rules="required" />
                                                        </div>
                                                        <ErrorMessage name="name" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Submit Button Color <a class="reset-default" @click="form.public_settings.submit_btn_bgcolor = defaultHabitTracking.public_settings.submit_btn_bgcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="button_bg_color" type="text" label="button background color">
                                                            <color-picker v-model="form.public_settings.submit_btn_bgcolor" />
                                                        </Field>
                                                        <ErrorMessage name="button_bg_color" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Submit Text Color <a class="reset-default" @click="form.public_settings.submit_btn_textcolor = defaultHabitTracking.public_settings.submit_btn_textcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="button_text_color" type="text" label="button text color">
                                                            <color-picker v-model="form.public_settings.submit_btn_textcolor" />
                                                        </Field>
                                                        <ErrorMessage name="button_text_color" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" ref="thankyousetting" @click="goTo('thankyousection')">
                                <div class="section_header">
                                    <h2>Thank you</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Background Color <a class="reset-default" @click="form.public_settings.thankyou_bgcolor = defaultHabitTracking.public_settings.thankyou_bgcolor">Reset to default</a></label>
                                                    <Field autocomplete="off" name="thankyou_bgcolor" v-model="form.public_settings.thankyou_bgcolor" type="text" label="element color">
                                                        <color-picker v-model="form.public_settings.thankyou_bgcolor" :classes="{ 'has-error': errors.thankyou_bgcolor }" />
                                                    </Field>
                                                    <ErrorMessage name="thankyou_bgcolor" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Text Color <a class="reset-default" @click="form.public_settings.thankyou_textcolor = defaultHabitTracking.public_settings.thankyou_textcolor">Reset to default</a></label>
                                                    <Field autocomplete="off" name="thankyou_textcolor" v-model="form.public_settings.thankyou_textcolor" type="text" label="element color">
                                                        <color-picker v-model="form.public_settings.thankyou_textcolor" :classes="{ 'has-error': errors.thankyou_textcolor }" />
                                                    </Field>
                                                    <ErrorMessage name="thankyou_textcolor" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" ref="ftrsetting">
                                <div class="section_header">
                                    <h2>Footer</h2>
                                    <span class="status" :style="`color: ${form.public_settings.display_footer ? '#2f7eed' : '#999'};`">{{ form.public_settings.display_footer ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-1">
                                        <div class="capsule_elm">
                                            <h5>Show</h5>
                                            <label for="has-login-footer" class="switch_option capsule_btn">
                                                <input type="checkbox" id="has-login-footer" :true-value="1" :false-value="0" v-model="form.public_settings.display_footer" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <p class="saved-style-text" @click="handleApplySavedStyle('footer')">Apply saved footer styling</p>
                                        <Transition duration="550" name="nested">
                                            <div class="mt-5" v-show="form.public_settings.display_footer">
                                                <div class="capsule_elm">
                                                    <h5>Facebook</h5>
                                                    <label for="facebook" class="switch_option capsule_btn">
                                                        <input type="checkbox" id="facebook" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_facebook" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="setting_wpr mb-1" v-if="form.public_settings.footer_has_facebook">
                                                    <div class="form_grp pt-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr">
                                                                <Field autocomplete="off" type="text" name="footer_facebook" v-model="form.public_settings.footer_facebook" placeholder="Facebook link here" rules="url|validate_url:facebook" label="facebook" />
                                                            </div>
                                                            <ErrorMessage name="footer_facebook" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="capsule_elm">
                                                    <h5>Twitter</h5>
                                                    <label for="twitter" class="switch_option capsule_btn">
                                                        <input type="checkbox" id="twitter" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_twitter" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="setting_wpr mb-1" v-if="form.public_settings.footer_has_twitter">
                                                    <div class="form_grp pt-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr">
                                                                <Field autocomplete="off" type="text" name="footer_twitter" v-model="form.public_settings.footer_twitter" placeholder="Twitter link here" rules="url|validate_url:twitter" label="twitter" />
                                                            </div>
                                                            <ErrorMessage name="footer_twitter" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="capsule_elm">
                                                    <h5>Instagram</h5>
                                                    <label for="instagram" class="switch_option capsule_btn">
                                                        <input type="checkbox" id="instagram" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_instagram" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="setting_wpr mb-1" v-if="form.public_settings.footer_has_instagram">
                                                    <div class="form_grp pt-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_instagram" v-model="form.public_settings.footer_instagram" placeholder="Instagram link here" rules="url|validate_url:instagram" label="instagram" />
                                                            </div>
                                                            <ErrorMessage name="footer_instagram" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="capsule_elm">
                                                    <h5>Linkedin</h5>
                                                    <label for="linkedin" class="switch_option capsule_btn">
                                                        <input type="checkbox" id="linkedin" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_linkedin" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="setting_wpr mb-1" v-if="form.public_settings.footer_has_linkedin">
                                                    <div class="form_grp pt-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_linkedin" v-model="form.public_settings.footer_linkedin" placeholder="Linkedin link here" rules="url|validate_url:linkedin" label="linkedin" />
                                                            </div>
                                                            <ErrorMessage name="footer_linkedin" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="capsule_elm">
                                                    <h5>Youtube</h5>
                                                    <label for="youtube" class="switch_option capsule_btn">
                                                        <input type="checkbox" id="youtube" :true-value="1" :false-value="0" v-model="form.public_settings.footer_has_youtube" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="setting_wpr mb-1" v-if="form.public_settings.footer_has_youtube">
                                                    <div class="form_grp pt-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="footer_youtube" v-model="form.public_settings.footer_youtube" placeholder="Youtube link here" rules="url|validate_url:youtu" label="youtube" />
                                                            </div>
                                                            <ErrorMessage name="footer_youtube" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="setting_wpr">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Icons Color <a class="reset-default" @click="form.public_settings.footer_textcolor = defaultHabitTracking.public_settings.footer_textcolor">Reset to default</a></label>
                                                            <Field autocomplete="off" name="footer_textcolor" v-model="form.public_settings.footer_textcolor" type="text" label="element color">
                                                                <color-picker v-model="form.public_settings.footer_textcolor"/>
                                                            </Field>
                                                            <ErrorMessage name="footer_textcolor" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Background Color <a class="reset-default" @click="form.public_settings.footer_bgcolor = defaultHabitTracking.public_settings.footer_bgcolor">Reset to default</a></label>
                                                            <Field autocomplete="off" name="footer_bgcolor" v-model="form.public_settings.footer_bgcolor" type="text" label="element color">
                                                                <color-picker v-model="form.public_settings.footer_bgcolor"/>
                                                            </Field>
                                                            <ErrorMessage name="footer_bgcolor" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                    <div class="color_container mt-2 mb-2" v-show="form.public_settings.display_footer">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Copyright Text</label>
                                                    <div class="field_wpr m-0">
                                                        <Field autocomplete="off" type="text" name="footer_company" v-model="form.public_settings.footer_company" :placeholder="`© ${new Date().getFullYear()} - ${user.businessname ? user.businessname : 'Company Name'}`" />
                                                    </div>
                                                    <ErrorMessage name="footer_company" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Terms Link</label>
                                                    <div class="field_wpr has_prefix m-0">
                                                        <Field autocomplete="off" type="text" name="footer_terms" v-model="form.public_settings.footer_terms" placeholder="https://superfitcoaching.com" rules="url" label="term link" />
                                                        <span class="prefix">URL</span>
                                                    </div>
                                                    <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                    <ErrorMessage name="footer_terms" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Privacy Link</label>
                                                    <div class="field_wpr has_prefix m-0">
                                                        <Field autocomplete="off" type="text" name="footer_policy" v-model="form.public_settings.footer_policy" placeholder="https://superfitcoaching.com" rules="url" label="privacy link" />
                                                        <span class="prefix">URL</span>
                                                    </div>
                                                    <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                    <ErrorMessage name="footer_policy" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="habitStep == 4">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Notifications</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <swiper :slidesPerView="'auto'" :spaceBetween="30" :navigation='true' class="tab_slider">
                                            <swiper-slide><span @click="handleReminderPreviewType('welcome')" :class="{ active: reminderTab === 'welcome' }">Habit Tracking Welcome <label class="tag" :style="`background: #${form.reminder.welcome_reminder ? '2f7eed' : 'f2a31d'}`">{{form.reminder.welcome_reminder ? "On" : "off"}}</label></span></swiper-slide>
                                            <swiper-slide><span @click="handleReminderPreviewType('reminders')" :class="{ active: reminderTab === 'reminders' }">Habit Reminders <label class="tag" :style="`background: #${form.reminder.reminder ? '2f7eed' : 'f2a31d'}`">{{form.reminder.reminder ? "On" : "off"}}</label></span></swiper-slide>
                                            <swiper-slide><span @click="handleReminderPreviewType('admin')" :class="{ active: reminderTab === 'admin' }">Admin Notiication <label class="tag" :style="`background: #${form.reminder.has_notification ? '2f7eed' : 'f2a31d'}`">{{form.reminder.has_notification ? "On" : "off"}}</label></span></swiper-slide>
                                        </swiper>
                                        <p class="notification-warning" v-if="form.reminder && (!form.reminder.welcome_reminder || !form.reminder.reminder || !form.reminder.has_notification)">Some of your notifications and reminders are set to OFF and this could affect habit tracking and scoring performance. You can adjust them here.</p>
                                        <Transition duration="550" name="nested">
                                            <div v-if="reminderTab === 'welcome'">
                                                <div class="capsule_elm">
                                                    <h5>Delivering?</h5>
                                                    <label for="award_point" class="switch_option capsule_btn">
                                                        <input type="checkbox" id="award_point" :true-value="1" :false-value="0" v-model="form.reminder.welcome_reminder" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <Transition duration="550" name="nested">
                                                    <div v-if="form.reminder.welcome_reminder" class="mt-2">
                                                        <div class="edit_section border mb-2">
                                                            <sending-method v-model="form.reminder.welcome_sending_method" />
                                                        </div>
                                                    </div>
                                                </Transition>
                                            </div>
                                        </Transition>
                                        <Transition duration="550" name="nested">
                                            <div v-if="reminderTab === 'reminders'">
                                                <div class="capsule_elm">
                                                    <h5>Delivering?</h5>
                                                    <label for="track_reminders" class="switch_option capsule_btn">
                                                        <input type="checkbox" id="track_reminders" :true-value="1" :false-value="0" v-model="form.reminder.reminder" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <Transition duration="550" name="nested">
                                                    <div v-if="form.reminder.reminder" class="mt-2">
                                                        <div class="edit_section border mb-2">
                                                            <sending-method v-model="form.reminder.sending_method" />
                                                        </div>
                                                    </div>
                                                </Transition>
                                            </div>
                                        </Transition>
                                        <Transition duration="550" name="nested">
                                            <div v-if="reminderTab === 'admin'">
                                                <div class="capsule_elm">
                                                    <h5>Delivering?</h5>
                                                    <label for="adm_notification" class="switch_option capsule_btn">
                                                        <input type="checkbox" id="adm_notification" :true-value="1" :false-value="0" v-model="form.reminder.has_notification" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <Transition duration="550" name="nested">
                                                    <div v-if="form.reminder.has_notification" class="mt-2">
                                                        <div class="edit_section border mb-2">
                                                            <sending-method v-model="form.reminder.notification_sending_method" />
                                                        </div>
                                                    </div>
                                                </Transition>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                                <div class="setting_wpr mt-3" v-if="reminderTab === 'welcome'">
                                    <div v-if="form.reminder.welcome_sending_method == 1 || form.reminder.welcome_sending_method == 3">
                                        <email-component v-model="welcomeEmail" :errors="errors" :has-email-button="true" :handle-default-email="handleWelcomeDefaultEmail" preview-module="habit-tracking" ref="welcome-email-component" is-full-view />
                                    </div>
                                    <div v-if="form.reminder.welcome_sending_method == 2 || form.reminder.welcome_sending_method == 3" class="sms-component">
                                        <sms-component v-model="form.reminder.welcome_sms_message" media-field-name="welcome_sms_media" :media="form.reminder.welcome_media" :default-sms-handler="true" preview-module="habit-tracking" :handle-default-sms="handleWelcomeDefaultSms" :update-sms-text="updateSmsText()" ref="welcome-sms-component" />
                                    </div>
                                </div>
                                <div class="setting_wpr mt-3" v-if="reminderTab === 'reminders'">
                                    <div v-if="form.reminder.sending_method == 1 || form.reminder.sending_method == 3">
                                        <email-component v-model="reminderEmail" :errors="errors" :has-email-button="true" :handle-default-email="handleReminderDefaultEmail" preview-module="habit-tracking" ref="reminder-email-component" is-full-view />
                                    </div>
                                    <div v-if="form.reminder.sending_method == 2 || form.reminder.sending_method == 3" class="sms-component">
                                        <sms-component v-model="form.reminder.sms_message" media-field-name="reminder_sms_media" :media="form.reminder.media" :default-sms-handler="true" preview-module="habit-tracking" :handle-default-sms="handleReminderDefaultSms" :update-sms-text="updateSmsText()" ref="message-sms-component" />
                                    </div>
                                </div>
                                <div class="setting_wpr mt-3" v-if="reminderTab === 'admin'">
                                    <div v-if="form.reminder.notification_sending_method == 1 || form.reminder.notification_sending_method == 3">
                                        <div class="color_container mb-3">
                                            <div class="form_grp p-0">
                                                <div class="group_item">
                                                    <label class="input_label">Email Recipient</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.notification_email_recipient }">
                                                        <Field autocomplete="off" name="notification_email_recipient" v-model="form.reminder.notification_email_recipient" rules="required" label="email recipient">
                                                            <vue-tags-input :tags="form.reminder.notification_email_recipient" @on-tags-changed="handleNotificationEmailRecipient" :add-tag-on-blur="true" />
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="notification_email_recipient" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <email-component v-model="notificationEmail" :errors="errors" :handle-default-email="handleNotificationDefaultEmail" preview-module="habit-tracking" ref="notification-email-component" is-full-view />
                                    </div>
                                    <div v-if="form.reminder.notification_sending_method == 2 || form.reminder.notification_sending_method == 3" class="sms-component">
                                        <div class="color_container mb-3">
                                            <div class="form_grp p-0">
                                                <div class="group_item">
                                                    <label class="input_label">SMS Recipient</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.notification_sms_recipient }">
                                                        <Field autocomplete="off" name="notification_sms_recipient" v-model="form.reminder.notification_sms_recipient" rules="required" label="sms recipient">
                                                            <vue-tags-input :tags="form.reminder.notification_sms_recipient" @on-tags-changed="handleNotificationSMSRecipient" :add-tag-on-blur="true" />
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="notification_sms_recipient" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <sms-component v-model="form.reminder.notification_sms_message" media-field-name="notification_sms_media" :media="form.reminder.notification_media" :default-sms-handler="true" preview-module="habit-tracking" :handle-default-sms="handleNotificationDefaultSms" :update-sms-text="updateSmsText()" ref="notification-sms-component" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="habitStep == 5">
                            <div class="section_content w-100 open_area" @click="goTo('seosection')" ref="seosetting">
                                <div class="section_header">
                                    <h2>SEO Settings</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Title</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_title }">
                                                    <Field autocomplete="off" type="text" name="seo_title" placeholder="ex: The title will be displayed in browser tabs." v-model="form.public_settings.seo_title" />
                                                </div>
                                                <ErrorMessage name="seo_title" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Keywords</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_keyword }">
                                                    <Field autocomplete="off" type="text" name="seo_keyword" placeholder="ex: Several keywords that describe your page best." v-model="form.public_settings.seo_keyword" />
                                                </div>
                                                <ErrorMessage name="seo_keyword" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Description</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.seo_desc }">
                                                    <Field autocomplete="off" type="textarea" name="seo_desc" v-model="form.seo_desc">
                                                        <textarea cols="10" rows="10" placeholder="Description Goes Here..." v-model="form.public_settings.seo_desc"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="seo_desc" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="capsule_elm">
                                        <h5>I want my page indexed by search engine</h5>
                                        <label for="page_indexed" class="switch_option capsule_btn">
                                            <input type="checkbox" id="page_indexed" :true-value="1" :false-value="0" v-model="form.public_settings.seo_index" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100 open_area" @click="goTo('sharesection')" ref="sharesetting">
                                <div class="section_header">
                                    <h2>Social Share</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <div class="setting_wpr">
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Title</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_share_title }">
                                                    <Field autocomplete="off" type="text" name="seo_share_title" placeholder="ex: New Title" v-model="form.public_settings.seo_share_title" />
                                                </div>
                                                <ErrorMessage name="seo_share_title" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Description</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.seo_share_desc }">
                                                    <Field autocomplete="off" type="textarea" name="seo_share_desc" v-model="form.public_settings.seo_share_desc">
                                                        <textarea cols="10" rows="10" placeholder="Description Goes Here..." v-model="form.public_settings.seo_share_desc"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="seo_share_desc" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <h3 class="sub_header mt-4">SEO Thumbnail</h3>
                                    <image-library v-model="form.public_settings.seo_thumb" image-type="social-share" />
                                </div>
                            </div>
                            <div class="section_content w-100 open_area" @click="goTo('favsection')" ref="favsetting">
                                <div class="section_header">
                                    <h2>Favicon</h2>
                                </div>
                                <div class="color_container mt-1">
                                    <image-library v-model="form.public_settings.seo_fav" image-type="favicon" />
                                </div>
                            </div>
                        </div>
                        <button type="button" class="preview_btn" @click="preview = true">Preview</button>
                    </div>
                    <div class="action_wpr">
                        <button type="button" class="btn cancel_btn" @click="closeModal()" v-if="habitStep == 1">Close</button>
                        <button type="button" class="btn cancel_btn" @click="habitStep -= 1" v-if="habitStep > 1">Back</button>
                        <button type="button" class="btn danger_btn" v-if="habitTracking.id" @click="handleDeleteHabitTracking([habitTracking.id])">Delete</button>
                        <button class="btn save_btn" id="habit-save-button" v-if="habitStep < 5"><i class="fa fa-spinner fa-spin" v-if="habitTrackingUpdateLoader"></i> {{ habitTrackingUpdateLoader ? 'Saving' : 'Next' }}</button>
                        <button class="btn save_btn" id="habit-save-button" v-if="habitStep == 5"><i class="fa fa-spinner fa-spin" v-if="habitTrackingUpdateLoader"></i> {{ habitTrackingUpdateLoader ? (isNew ? 'Saving' : 'Updating') : (isNew ? 'Finish' : 'Update') }}</button>
                    </div>
                </Form>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="habitStep == 1">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                    </div>
                </div>
                <div class="cell">
                    <div class="content_area">
                        <div class="px-3 mt-4">
                            <v-calendar :first-day-of-week="2" :attributes="calendarAttributes"  is-expanded>
                                <template #day-popover="{ dayTitle }">
                                    <div class="task_details">
                                        <div class="task_header">
                                            {{ dayTitle }}
                                        </div>
                                    </div>
                                </template>
                            </v-calendar>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="habitStep == 2 || habitStep == 3">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="full_preview" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="large">Full View</h5>
                            <input type="checkbox" id="full_preview" v-model="fullPreview" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <ul class="alt_prev">
                    <li @click="previewPage = 'habit'" :class="{'active' : previewPage == 'habit'}">Habits</li>
                    <li @click="previewPage = 'thankyou'" :class="{'active' : previewPage == 'thankyou'}">Thank You</li>
                </ul>
                <div class="cell">
                    <div class="content_area">
                        <preview-content v-show="previewPage == 'habit'" :habit-tracking="form" ref="preview-content" :is-new="isNew" />
                        <thankyou-preview-content v-show="previewPage == 'thankyou'" :habit-tracking="form" ref="preview-content" :is-new="isNew" />
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="habitStep == 4">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_notification" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_notification" v-model="previewReminder" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <ul class="alt_prev">
                    <li @click="reminderPreviewType = 'email'" :class="{'active' : reminderPreviewType == 'email'}" v-if="(form.reminder.welcome_sending_method == 3 && reminderTab == 'welcome') || (form.reminder.welcome_sending_method == 1 && reminderTab == 'welcome')">E-mail</li>
                    <li @click="reminderPreviewType = 'sms'" :class="{'active' : reminderPreviewType == 'sms'}" v-if="(form.reminder.welcome_sending_method == 3 && reminderTab == 'welcome') || (form.reminder.welcome_sending_method == 2  && reminderTab == 'welcome')">SMS</li>
                    <li @click="reminderPreviewType = 'email'" :class="{'active' : reminderPreviewType == 'email'}" v-if="(form.reminder.sending_method == 3 && reminderTab == 'reminders') || (form.reminder.sending_method == 1 && reminderTab == 'reminders')">E-mail</li>
                    <li @click="reminderPreviewType = 'sms'" :class="{'active' : reminderPreviewType == 'sms'}" v-if="(form.reminder.sending_method == 3 && reminderTab == 'reminders') || (form.reminder.sending_method == 2  && reminderTab == 'reminders')">SMS</li>
                    <li @click="reminderPreviewType = 'email'" :class="{'active' : reminderPreviewType == 'email'}" v-if="(form.reminder.notification_sending_method == 3 && reminderTab == 'admin') || (form.reminder.notification_sending_method == 1  && reminderTab == 'admin')">E-mail</li>
                    <li @click="reminderPreviewType = 'sms'" :class="{'active' : reminderPreviewType == 'sms'}" v-if="(form.reminder.notification_sending_method == 3 && reminderTab == 'admin') || (form.reminder.notification_sending_method == 2  && reminderTab == 'admin')">SMS</li>
                </ul>
                <div class="cell">
                    <div class="content_area" v-if="reminderPreviewType == 'email'">
                        <div class="msg_preview" v-if="(form.reminder.welcome_sending_method == 3 && reminderTab == 'welcome') || (form.reminder.welcome_sending_method == 1 && reminderTab == 'welcome')">
                            <h2>{{ replaceVariables(welcomeEmail.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="welcomeEmail.show_email_logo">
                                <img :src="welcomeEmail.email_logo ? welcomeEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(welcomeEmail.email, welcomeEmail.email_button_bg_color, welcomeEmail.email_button_text_color, 'View Habit Request')"></div>
                            <div v-html="generateSignature(welcomeEmail.is_signature, welcomeEmail.signature_id)"></div>
                        </div>
                        <div class="msg_preview" v-if="(form.reminder.sending_method == 3 && reminderTab == 'reminders') || (form.reminder.sending_method == 1 && reminderTab == 'reminders')">
                            <h2>{{ replaceVariables(reminderEmail.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="reminderEmail.show_email_logo">
                                <img :src="reminderEmail.email_logo ? reminderEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(reminderEmail.email, reminderEmail.email_button_bg_color, reminderEmail.email_button_text_color, 'View Habit Request')"></div>
                            <div v-html="generateSignature(reminderEmail.is_signature, reminderEmail.signature_id)"></div>
                        </div>
                        <div class="msg_preview" v-if="(form.reminder.notification_sending_method == 3 && reminderTab == 'admin') || (form.reminder.notification_sending_method == 1 && reminderTab == 'admin')">
                            <h2>{{ replaceVariables(notificationEmail.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="notificationEmail.show_email_logo">
                                <img :src="notificationEmail.email_logo ? notificationEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(notificationEmail.email, notificationEmail.email_button_bg_color, notificationEmail.email_button_text_color, 'View Habit Request')"></div>
                            <div v-html="generateSignature(notificationEmail.is_signature, notificationEmail.signature_id)"></div>
                        </div>
                        <div class="msgFooter text-center mt-2 mb-2">
                            <p>
                                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                    <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                    <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                </template>
                                <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                            </p>
                            <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                            <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                        </div>
                    </div>
                    <div class="content_area" v-if="reminderPreviewType == 'sms'">
                        <div class="sms_preview">
                            <div v-if="(form.reminder.welcome_sending_method == 2 && reminderTab == 'welcome') || (form.reminder.welcome_sending_method == 3 && reminderTab == 'welcome')" class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(welcomeSmsText)"></div>
                            <div v-if="(form.reminder.sending_method == 2 && reminderTab == 'reminders') || (form.reminder.sending_method == 3 && reminderTab == 'reminders')" class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(messageSmsText)"></div>
                            <div v-if="(form.reminder.notification_sending_method == 2 && reminderTab == 'admin') || (form.reminder.notification_sending_method == 3 && reminderTab == 'admin')" class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(notificationSmsText)"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="habitStep == 5">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                    </div>
                </div>
                <div class="area_wpr">
                    <h3 class="sub_header" ref="seosection" @click="setting('seosetting')">Search Engine Listing</h3>
                    <div class="google_ranking">
                        <h6>About 5,29,000 results(0.36 seconds)</h6>
                        <h4>{{ form.public_settings.seo_title ? form.public_settings.seo_title : 'Lorem Ipsum Dolor' }}</h4>
                        <p>{{ form.public_settings.seo_desc ? form.public_settings.seo_desc : 'It takes whole chocolate protein. The members of the fleet are turpis at the ends of the pulvinar. No vehicles are accepted at the bed.' }}</p>
                    </div>
                </div>
                <div class="area_wpr" ref="sharesection" @click="setting('sharesetting')">
                    <h3 class="sub_header">Social Share</h3>
                    <div class="seo_card">
                        <img :src="form.public_settings.seo_thumb ? form.public_settings.seo_thumb : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                        <div class="seo_info">
                            <h3>{{ form.public_settings.seo_share_title ? form.public_settings.seo_share_title : 'Optimize Your Metabolism In 30 Days' }}</h3>
                            <p>{{ form.public_settings.seo_share_desc ? form.public_settings.seo_share_desc : 'It is easy to speed upa slow metabolism in as little as 30 days using this proven technique.' }}</p>
                        </div>
                    </div>
                </div>
                <div class="area_wpr" ref="favsection" @click="setting('favsetting')">
                    <h3 class="sub_header">Favicon</h3>
                    <div class="browser_layout">
                        <ul class="browser_tabs">
                            <li>
                                <img :src="form.public_settings.seo_fav ? form.public_settings.seo_fav : (env.VUE_APP_NAME ? require('@/assets/images/'+env.VUE_APP_NAME+'/logo.svg') : require('@/assets/images/logo.png'))" alt="">
                                <h6>{{ form.public_settings.seo_title ? (form.public_settings.seo_title.length > 15 ? form.public_settings.seo_title.substr(0, 15)+'...' : form.public_settings.seo_title) : 'Thrive Coach' }}</h6>
                                <i class="fas fa-times"></i>
                            </li>
                            <li><i class="fas fa-plus"></i></li>
                        </ul>
                        <div class="browser_info">
                            <i class="fas fa-arrow-left"></i>
                            <i class="fas fa-arrow-right"></i>
                            <i class="fas fa-redo"></i>
                            <div class="browser_search">
                                <i class="fas fa-lock"></i>
                                <p>https://thrivecoach.io</p>
                                <i class="far fa-star ml-auto"></i>
                            </div>
                            <img src="@/assets/images/default-avatar.svg" class="user" alt=""/>
                            <i class="fas fa-ellipsis-v"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <reminder-preview v-model="previewReminder" :reminder-tab="reminderTab" :form="form" :welcome-email="welcomeEmail" :reminder-email="reminderEmail"  :notification-email="notificationEmail" />
        <preview-habit-tracking v-model="fullPreview" :habit-tracking="form" :preview-page="previewPage" />
        <category-habit-preview v-model="categoryHabitPreview" :habit="selectedCategoryHabit" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { copyText } from 'vue3-clipboard'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions, mapGetters } from 'vuex'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import SwiperCore, { Navigation } from 'swiper'

    const ReminderPreview = defineAsyncComponent(() => import('@/pages/habit-tracking/components/ReminderPreview'))
    const PreviewContent = defineAsyncComponent(() => import('@/pages/habit-tracking/components/PreviewContent'))
    const ThankyouPreviewContent = defineAsyncComponent(() => import('@/pages/habit-tracking/components/ThankyouPreviewContent'))
    const PreviewHabitTracking = defineAsyncComponent(() => import('@/pages/habit-tracking/components/PreviewHabitTracking'))
    const CategoryHabitPreview = defineAsyncComponent(() => import('@/pages/habit-tracking/components/CategoryHabitPreview'))

    import VueTagsInput from 'vue3-tags-input'
    import Draggable from 'vuedraggable'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'
    import 'swiper/swiper-bundle.min.css'

    SwiperCore.use([Navigation])

    export default {
        name: 'Habit tracking wizard',

        data () {
            return {
                habitStep: 1,
                preview: true,
                toggleAll: 1,
                form: {
                    name: '',
                    access_code: '',
                    url: '',
                    recurring_type: 3,
                    is_cumulative: 1,
                    recurring_settings: {
                        hours: 1,
                        day: 1,
                        days: 1,
                        weeks: 1,
                        months: 1,
                        week: 1,
                        month: 1,
                        years: 1,
                        end_type: 1,
                        end_occurrences: 1,
                        end_date: '',
                        week_name: 'mon',
                        weekdays: {
                            mon: false,
                            tue: false,
                            wed: false,
                            thu: false,
                            fri: false,
                            sat: false,
                            sun: true,
                        },
                        repeat: 1,
                        send_before: 5,
                        send_before_type: 3,
                        send_at: 7,
                        send_at_type: 'PM',
                        time_zone: this.user ? this.user.timezone : '',
                        day_type: 'd',
                    },
                    reminder:{
                        has_reminder: 1,
                        sending_method: 1,
                        throttle_for_user_tz: 1,
                        recipient_email:'',
                        recipient_mobile:'',
                        email_subject: '',
                        email_message: '',
                        sms_message: '',
                        media: [],
                        show_email_logo: false,
                        show_email_signature: false,
                        signature_id: 0,
                        logo_image: '',
                        welcome_sending_method: 1,
                        welcome_email_subject: '',
                        welcome_email_message: '',
                        welcome_sms_message: '',
                        show_welcome_email_logo: false,
                        welcome_logo_image: '',
                        welcome_media: [],
                        has_notification: 1,
                        notification_sending_method: 1,
                        notification_email_subject: '',
                        notification_email_message: '',
                        notification_sms_message: '',
                        show_notification_email_logo: false,
                        notification_logo_image: '',
                        notification_media: [],
                        notification_email_recipient: [this.user ? this.user.email : ''],
                        notification_sms_recipient: [this.user ? this.user.sms_notification_number : ''],
                        show_welcome_signature: 0,
                        welcome_signature_id: 0,
                        show_signature: 0,
                        welcome_reminder: 1,
                        reminder: 1,
                        welcome_email_button_bg_color: '',
                        welcome_email_button_text_color: '',
                        email_button_bg_color: '',
                        email_button_text_color: '',
                        notification_email_button_bg_color: '',
                        notification_email_button_text_color: '',
                    },
                    data_settings: {
                        total_submission: 6,
                        limited_submission: 0,
                        collapse_public_questions: 1,
                    },
                    public_settings: {
                        display_explainer_video: 0,
                        explainer_video_type: 0,
                        explainer_video: '',
                        explainer_embed: '',
                        display_company_branding: 1,
                        company_branding: '',
                        display_cover: 0,
                        cover_type: 2,
                        headline: 'Time To Submit Your Habits',
                        subheadline: '',
                        display_logo: 0,
                        logo: '',
                        display_author: 0,
                        author:'',
                        display_header: 1,
                        display_footer: 0,
                        header_bgcolor: '#FFF',
                        header_textcolor: '#000',
                        submit_btn_text:  'Submit',
                        element_color:  '#D4D4D4',
                        element_text_color: '#2C3E50',
                        submit_btn_textcolor: '#fff',
                        submit_btn_bgcolor: '#2c3e50',
                        footer_bgcolor: '#D4D4D4',
                        footer_textcolor: '#2C3E50',
                        cover_color:  '#1976d2',
                        cover_textcolor:  '#2C3E50',
                        cover: '',
                        small_cover: '',
                        seo_title: 'Join our Habit Challenge',
                        seo_keyword: 'Habits',
                        seo_desc: 'Whatever can be measured can be improved.',
                        seo_index: 0,
                        seo_share_title: 'Join our habit challenge',
                        seo_share_desc: 'Whatever can be measured can be improved',
                        seo_thumb: '',
                        seo_fav: '',
                        footer_has_facebook: 1,
                        footer_has_twitter: 1,
                        footer_has_instagram: 1,
                        footer_has_linkedin: 0,
                        footer_has_youtube: 0,
                        thankyou_bgcolor: '#FFFFFF',
                        thankyou_textcolor: '#2C3E50',
                        overlay_color: '#2c3e50',
                        overlay_opacity: 50,
                    },
                    habits: [
                        {
                            id: 0,
                            habit_tracking_category_id: 0,
                            name: '',
                            description: '',
                            requirements: '',
                            success_goal: 1,
                            submit_proof: false,
                        }
                    ],
                    categories: [
                        {
                            id: 0,
                            name: 'New Category',
                            habit_tracking_id: 0,
                        }
                    ],
                    headline_setting: {},
                    subheadline_setting: {},
                    calculation_type: 0,
                    apply_saved_styling: 0,
                },
                months: {
                    1: 'January',
                    2: 'February',
                    3: 'March',
                    4: 'April',
                    5: 'May',
                    6: 'June',
                    7: 'July',
                    8: 'August',
                    9: 'September',
                    10: 'October',
                    11: 'November',
                    12: 'December'
                },
                reminderOption: [{
                        reminder_value: '',
                        reminder_type: 'on time',
                    },
                    {
                        reminder_value: 5,
                        reminder_type: 'minutes',
                    },
                    {
                        reminder_value: 10,
                        reminder_type: 'minutes',
                    },
                    {
                        reminder_value: 20,
                        reminder_type: 'minutes',
                    },
                    {
                        reminder_value: 30,
                        reminder_type: 'minutes',
                    },
                    {
                        reminder_value: 1,
                        reminder_type: 'hour',
                    },
                    {
                        reminder_value: 2,
                        reminder_type: 'hours',
                    },
                    {
                        reminder_value: 5,
                        reminder_type: 'hours',
                    },
                    {
                        reminder_value: 10,
                        reminder_type: 'hours',
                    },
                    {
                        reminder_value: '',
                        reminder_type: 'custom',
                    }
                ],
                habitTrackingCategories: [],
                habitTrackingHabits: [],
                isNew: false,
                hours: {
                    mode: 'single',
                    value: [7],
                    options: [
                        { value: 1, label: '1' },
                        { value: 2, label: '2' },
                        { value: 3, label: '3' },
                        { value: 4, label: '4' },
                        { value: 5, label: '5' },
                        { value: 6, label: '6' },
                        { value: 7, label: '7' },
                        { value: 8, label: '8' },
                        { value: 9, label: '9' },
                        { value: 10, label: '10' },
                        { value: 11, label: '11' },
                        { value: 12, label: '12' },
                    ],
                    createTag: true
                },
                clock: {
                    mode: 'single',
                    value: ['AM'],
                    options: [
                        { value: 'AM', label: 'AM' },
                        { value: 'PM', label: 'PM' }
                    ],
                    createTag: true
                },
                intervalTimeone: {},
                weekdays: [
                    'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'
                ],
                reminderTab: 'welcome',
                welcomeEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                reminderEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                notificationEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                welcome_sms_media: [],
                reminder_sms_media: [],
                notification_sms_media: [],
                activeHabit: 0,
                headerTab: 'logo',
                headlineSetting: false,
                subheadlineSetting: false,
                calendarAttributes: [],
                editedCategory: 0,
                categoryHabitListDialog: false,
                categoryHabitListIndex: 0,
                selectedCategory: {},
                addHabitLoader: [],
                habitUpdateWatcher: false,
                reminderPreviewType: 'email',
                whiteLabel: {},
                previewReminder: false,
                fullPreview: false,
                categoryHabitPreview: false,
                selectedCategoryHabit: {},
                env: {},
                welcomeSmsText: '',
                messageSmsText: '',
                notificationSmsText: '',
                previewPage: 'habit',
                switchStep: true,
                editedHabitId: 0,
                lastStep: 0,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Form,
            Field,
            ErrorMessage,
            VueTagsInput,
            Draggable,
            ReminderPreview,
            PreviewContent,
            PreviewHabitTracking,
            CategoryHabitPreview,
            ThankyouPreviewContent,
            Swiper,
            SwiperSlide,
        },

        watch: {
            modelValue (val) {
                const vm = this;

                if (val) {
                    vm.previewPage  = 'habit';
                    vm.activeHabit  = 0;
                    vm.switchStep   = true;
                    vm.resetForm();
                } else {
                    vm.resetToDefaultDataValues();
                }
            },

            habitTracking (val) {
                if (val) {
                    const vm = this;

                    vm.resetForm(false);
                }
            },

            welcome_sms_media (media) {
                const vm = this;

                vm.form.reminder.welcome_media = media;
            },

            reminder_sms_media (media) {
                const vm = this;

                vm.form.reminder.media = media;
            },

            notification_sms_media (media) {
                const vm = this;

                vm.form.reminder.notification_media = media;
            },

            'form.recurring_type' (type) {
                const vm = this;

                vm.resetCalendar();
            },

            'form.recurring_settings.weekdays': {
                handler (days) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            habitUpdateWatcher (val) {
                const vm = this;

                if (val) {
                    setTimeout(function () {
                        vm.habitUpdateWatcher = false;
                        vm.handleCategoryHabitAutosave();
                    }, 1000);
                }
            },

            'form.recurring_settings.end_type': {
                handler (type) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.end_occurrences': {
                handler (day) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.end_date': {
                handler (date) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.days': {
                handler (days) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.day_type': {
                handler (type) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.weeks': {
                handler (week) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.send_at': {
                handler (hour) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.recurring_settings.send_at_type': {
                handler (meridiem) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'vm.form.recurring_settings.end_type': {
                handler (type) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.data_settings.total_submission': {
                handler (val) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.data_settings.limited_submission': {
                handler (val) {
                    const vm = this;

                    vm.resetCalendar();
                },
                deep: true
            },

            'form.reminder.welcome_sending_method': {
                handler (val) {
                    const vm = this;

                    vm.reminderPreviewType = val == 2 ? 'sms' : 'email';
                },
                deep: true,
            },

            'form.reminder.sending_method': {
                handler (val) {
                    const vm = this;

                    vm.reminderPreviewType = val == 2 ? 'sms' : 'email';
                },
                deep: true,
            },

            'form.reminder.notification_sending_method': {
                handler (val) {
                    const vm = this;

                    vm.reminderPreviewType = val == 2 ? 'sms' : 'email';
                },
                deep: true,
            },

            fullPreview (val) {
                const vm = this;

                if (val) {
                    document.body.classList.add('modal-open');

                    const button = document.getElementById('habit-save-button');

                    if (button) {
                        vm.switchStep   = false;
                        vm.closeWindow  = false;
                        button.click();
                    }
                } else {
                    document.body.classList.remove('modal-open');
                }

                vm.toggleGistChatBox();
            },
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                membership: state => state.authModule.membership,
                timezones: state => state.commonModule.timezones,
                habitCategories: state => state.habitTrackingModule.habitCategories,
                habitTracking: state => state.habitTrackingModule.selectedHabitTracking,
                habitTrackingUpdateLoader: state => state.habitTrackingModule.habitTrackingUpdateLoader,
                fontFamily: state => state.commonModule.fontFamily,
                fontWeight: state => state.commonModule.fontWeight,
                defaultHabitTracking: state => state.habitTrackingModule.habitTrackingDefaultSettings,
                habitTrackingDefaultContent: state => state.commonModule.habitTrackingDefaultContent,
            }),

            ...mapGetters({
                categoryHasHabit: 'habitTrackingModule/CATEGORY_HAS_HABIT',
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),
        },

        mounted () {
            const vm = this;

            vm.resetCalendar();

            if (!vm.timezones.length) {
                vm.getTimezones();
            }

            vm.whiteLabel          = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};
            vm.env                 = process.env;

            window.addEventListener("resize", vm.resizeEventHandler);
            vm.resizeEventHandler();

            if (!Object.values(vm.habitTrackingDefaultContent).length) {
                vm.getDefaultContentByType(5);
            }
        },

        unmounted() {
            const vm = this;

            window.removeEventListener("resize", vm.resizeEventHandler);
        },

        methods: {
            ...mapActions({
                saveHabitTracking: 'habitTrackingModule/saveHabitTracking',
                saveHabitCategory: 'habitTrackingModule/saveHabitCategory',
                deleteCategory: 'habitTrackingModule/deleteCategory',
                sortCategory: 'habitTrackingModule/sortCategory',
                sortCategoryHabits: 'habitTrackingModule/sortCategoryHabits',
                createHabit: 'habitTrackingModule/createHabit',
                getHabitCategories: 'habitTrackingModule/getHabitCategories',
                saveHabitTrackingHabits: 'habitTrackingModule/saveHabitTrackingHabits',
                getTimezones: 'commonModule/getTimezones',
                deleteHabit: 'habitTrackingModule/deleteHabit',
                deleteHabitTracking: 'habitTrackingModule/deleteHabitTracking',
                refreshHabitTracking: 'habitTrackingModule/refreshHabitTracking',
                getDefaultContentByType: 'commonModule/getDefaultContentByType',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);

                if (vm.$parent.$parent && vm.$parent.$parent.getHabitTrackings) {
                    vm.$parent.$parent.getHabitTrackings(vm.$parent.$parent.params);
                } else {
                    vm.$parent.getHabitTrackings(vm.$parent.params);
                }
            },

            calcWorkingDays (days) {
                var fromDate = new Date();
                var count = 0;
                while (count < days) {
                    fromDate.setDate(fromDate.getDate() + 1);
                    if (fromDate.getDay() != 0 && fromDate.getDay() != 6) // Skip weekends
                        count++;
                }
                return fromDate.getTime();
            },

            resetCalendar () {
                const vm = this;
                const date = new Date();
                let todayDate       = new Date();
                let weekdays        = [1,2, 3, 4, 5, 6,7];
                let dailyInterval   = 1;
                let weeklyInterval  = 1;
                let endDate         = '';
                let startDate       = new Date();
                let sendTimeHour    = '';

                //Start date based on timezone
                if ( vm.form.recurring_settings.send_at_type ==  'PM' ){
                    sendTimeHour = parseInt( vm.form.recurring_settings.send_at ) + 12;
                } else {
                    sendTimeHour = parseInt(vm.form.recurring_settings.send_at);
                }

                if (sendTimeHour < todayDate.getHours()){
                    startDate.setDate(startDate.getDate() + 1);
                }

                //Occurances selected
                if ( vm.form.recurring_settings.end_type == 2) {
                    let endOccurrences = vm.form.recurring_settings.end_occurrences;

                    endOccurrences = endOccurrences > 0 ? parseInt(endOccurrences) : 0;

                    if ( vm.form.recurring_settings.day_type == 'd' ){
                        dailyInterval = vm.form.recurring_settings.days ? vm.form.recurring_settings.days : 1;
                        endOccurrences = endOccurrences *  dailyInterval;
                        endOccurrences = parseInt(endOccurrences);
                    }

                    if ( vm.form.recurring_type == 3 ) {
                        weeklyInterval = vm.form.recurring_settings.weeks ? vm.form.recurring_settings.weeks : 1;
                        let weekdayscount = weeklyInterval * 7;
                        endOccurrences = endOccurrences * weekdayscount;
                        endOccurrences = parseInt(endOccurrences);
                    }

                    if ( sendTimeHour > todayDate.getHours() ) {
                        endOccurrences = endOccurrences - 1;
                    }

                    if ( vm.form.recurring_settings.day_type == 'w' && vm.form.recurring_type == 2 ) {
                        endDate = vm.calcWorkingDays(endOccurrences);
                    } else {
                        endDate = date.setDate(date.getDate() + endOccurrences);
                    }
                }

                // if limit selected
                if ( vm.form.recurring_settings.end_type == 1 && vm.form.data_settings.limited_submission == 1 ) {
                    let submissionsLimit = vm.form.data_settings.total_submission;

                    submissionsLimit = submissionsLimit > 0 ? parseInt(submissionsLimit) : 0;

                    if ( vm.form.recurring_settings.day_type == 'd' ){
                        dailyInterval = vm.form.recurring_settings.days ? vm.form.recurring_settings.days : 1;
                        submissionsLimit = submissionsLimit *  dailyInterval;
                        submissionsLimit = parseInt(submissionsLimit);
                    }

                    if ( vm.form.recurring_type == 3 ) {
                        weeklyInterval = vm.form.recurring_settings.weeks ? vm.form.recurring_settings.weeks : 1;
                        let weekdayscount = weeklyInterval * 7;
                        submissionsLimit = submissionsLimit * weekdayscount;
                        submissionsLimit = parseInt(submissionsLimit);
                    }

                    if ( sendTimeHour > todayDate.getHours() ){
                        submissionsLimit = submissionsLimit - 1;
                    }

                    if ( vm.form.recurring_settings.day_type == 'w' && vm.form.recurring_type == 2 ) {
                        endDate = vm.calcWorkingDays(submissionsLimit);
                    } else if (vm.form.recurring_type == 3) {
                        endDate = date.setDate(date.getDate() + submissionsLimit);
                    }

                } else if (vm.form.recurring_settings.end_type == 1 && vm.form.data_settings.limited_submission == 0) {
                    endDate = '';
                }

                //End On selected
                if ( vm.form.recurring_settings.end_type == 3 ) {
                    endDate = vm.form.recurring_settings.end_date;

                    let CurrentDate = new Date();

                    if (CurrentDate > endDate) {
                        endDate = CurrentDate;
                    }
                }

                // For Daily basis
                if ( vm.form.recurring_type == 2 ) {
                    //Every Day's
                    if (vm.form.recurring_settings.day_type == 'd') {
                        dailyInterval = vm.form.recurring_settings.days ? vm.form.recurring_settings.days : 1;
                    }

                    //Every Work Day
                    if (vm.form.recurring_settings.day_type == 'w') {
                       weekdays   = [2, 3, 4, 5, 6];
                    }
                }

                // For Weekly basis
                if (vm.form.recurring_type == 3) {
                    let day = 'sun';
                    const weeks = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
                    Object.keys(vm.form.recurring_settings.weekdays).forEach((week) => {
                        if (vm.form.recurring_settings.weekdays[week] == true) {
                            day = week;
                        }
                    });
                    const index = weeks.findIndex(w => w == day);
                    weekdays = parseInt([index + 1]);

                    weeklyInterval = vm.form.recurring_settings.weeks ? vm.form.recurring_settings.weeks : 1;

                    // For occurances start from next week
                    let startDay    = parseInt(startDate.getDay());

                    if (( startDay >= weekdays || weekdays == 1 )) {
                        let dayDiff = 0;

                        if ( weekdays == 1 ) {
                            dayDiff = 7 - startDay;
                        } else {
                            dayDiff = startDay - (startDay - weekdays);
                        }

                        startDate.setDate(startDate.getDate() + parseInt(dayDiff));
                    }
                }

                if (startDate > endDate && endDate) {
                    startDate = endDate;
                }

                vm.calendarAttributes = [{
                                            dates: {
                                                start: startDate,
                                                end: endDate,
                                                weekdays,
                                                dailyInterval,
                                                weeklyInterval
                                            },
                                            highlight: {
                                                class: false,
                                                fillMode: 'solid',
                                                contentClass: 'assign_day',
                                                style: {
                                                    backgroundColor: '#2F7FED',
                                                }
                                            },
                                            popover: {
                                                slot: 'todo-row',
                                            },
                                        }];
            },

            resetForm (force = true) {
                const vm = this;

                if (force) {
                    vm.habitStep = 1;
                    setTimeout(function () {
                        vm.reminderTab = 'welcome';
                    }, 1000);

                    vm.resetHabitTracking();

                    if (vm.habitTracking.id) {
                        vm.isNew = false;
                    }
                }

                if (vm.habitTracking.id) {
                    setTimeout(function () {
                        const habitTracking = JSON.parse(JSON.stringify(vm.habitTracking));

                        if (habitTracking.public_settings && habitTracking.public_settings.cover === undefined) {
                            habitTracking.public_settings.cover = '';
                            habitTracking.public_settings.small_cover = '';
                        }

                        if (habitTracking.recurring_settings && habitTracking.recurring_settings.time_zone === undefined) {
                            habitTracking.recurring_settings.time_zone = vm.authUser.timezone;
                        }

                        let headline_setting = habitTracking.headline_setting ? habitTracking.headline_setting : {};
                        let subheadline_setting = habitTracking.subheadline_setting ? habitTracking.subheadline_setting : {};

                        if (!headline_setting.font_size) {
                            headline_setting = {
                                font_family: 'Inter',
                                font_weight: 600,
                                font_size: 50,
                                font_color: habitTracking.public_settings.cover_textcolor,
                                has_shadow: 0,
                                shadow_x: 0,
                                shadow_y: 4,
                                shadow_blur: 4,
                                shadow_color: 'rgba(0, 0, 0, 0.25)',
                            };
                        }

                        if (!subheadline_setting.font_size) {
                            subheadline_setting = {
                                font_family: 'Inter',
                                font_weight: 600,
                                font_size: 25,
                                font_color: '#2C3E50',
                                has_shadow: 0,
                                shadow_x: 0,
                                shadow_y: 4,
                                shadow_blur: 4,
                                shadow_color: 'rgba(0, 0, 0, 0.68)',
                            };
                        }

                        const applySavedStyling = vm.form.apply_saved_styling ? vm.form.apply_saved_styling : 0;

                        vm.form = habitTracking;
                        vm.form.headline_setting = headline_setting;
                        vm.form.subheadline_setting = subheadline_setting;
                        vm.form.calculation_type = habitTracking.calculation_type ? habitTracking.calculation_type : 0;
                        vm.form.apply_saved_styling = applySavedStyling;

                        if (vm.form.habits === undefined) {
                            vm.habitTrackingHabits = [{
                                                          id: 0,
                                                          habit_tracking_category_id: 0,
                                                          name: '',
                                                          description: '',
                                                          requirements: '',
                                                          success_goal: 1,
                                                          submit_proof: false,
                                                      }];
                        } else {
                            vm.habitTrackingHabits = vm.form.habits;
                        }

                        if (vm.form.categories === undefined) {
                            vm.habitTrackingCategories = [{ id: 0, name: 'New Category', habit_tracking_id: vm.habitTracking.id }];
                        } else {
                            vm.habitTrackingCategories = vm.form.categories;

                            if (!vm.activeHabit) {
                                vm.activeHabit = vm.habitCategories[0] && vm.habitCategories[0].habits && vm.habitCategories[0].habits[0] ? vm.habitCategories[0].habits[0].id : 0;
                            }
                        }

                        if (vm.form.recurring_settings && !vm.form.recurring_settings.day) {
                            vm.form.recurring_settings.day = 1;
                        }

                        const activeWeekdays = vm.form.recurring_settings.weekdays ? Object.values(vm.form.recurring_settings.weekdays).filter(day => day == true) : [];

                        if (activeWeekdays.length > 1) {
                            vm.form.recurring_settings.weekdays.mon = false;
                            vm.form.recurring_settings.weekdays.tue = false;
                            vm.form.recurring_settings.weekdays.wed = false;
                            vm.form.recurring_settings.weekdays.thu = false;
                            vm.form.recurring_settings.weekdays.fri = false;
                            vm.form.recurring_settings.weekdays.sat = false;
                            vm.form.recurring_settings.weekdays.sun = true;
                        }

                        if (vm.form.public_settings && vm.form.public_settings.explainer_video_type === undefined) {
                            vm.form.public_settings.explainer_video_type  = 0;
                            vm.form.public_settings.explainer_embed = '';
                        }

                        if (vm.form.reminder.welcome_sending_method == undefined) {
                            vm.form.reminder.welcome_sending_method  = 1;
                            vm.form.reminder.welcome_email_subject   = vm.getDefaultMessage('email-subject', 'welcome-reminder');
                            vm.form.reminder.welcome_email_message   = vm.getDefaultMessage('email-content', 'welcome-reminder');
                            vm.form.reminder.welcome_sms_message     = vm.getDefaultMessage('sms-content', 'welcome-reminder');
                            vm.form.reminder.show_welcome_email_logo = false;
                            vm.form.reminder.welcome_logo_image      = '';
                            vm.form.reminder.welcome_media           = [];
                        }

                        if (vm.form.reminder.notification_sending_method == undefined) {
                            vm.form.reminder.has_notification             = 0;
                            vm.form.reminder.notification_sending_method  = 1;
                            vm.form.reminder.notification_email_subject   = vm.getDefaultMessage('email-subject', 'notification-reminder');
                            vm.form.reminder.notification_email_message   = vm.getDefaultMessage('email-content', 'notification-reminder');
                            vm.form.reminder.notification_sms_message     = vm.getDefaultMessage('sms-content', 'notification-reminder');
                            vm.form.reminder.show_notification_email_logo = false;
                            vm.form.reminder.notification_logo_image      = '';
                            vm.form.reminder.notification_media           = [];
                            vm.form.reminder.notification_email_recipient = [vm.authUser.email];
                            vm.form.reminder.notification_sms_recipient   = [vm.authUser.sms_notification_number];
                        } else if (typeof vm.form.reminder.notification_email_recipient == 'string') {
                            vm.form.reminder.notification_email_recipient = [vm.authUser.email];
                            vm.form.reminder.notification_sms_recipient   = [vm.authUser.sms_notification_number];
                        }

                        if (vm.form.reminder.show_welcome_signature == undefined) {
                            vm.form.reminder.show_welcome_signature = 0;
                            vm.form.reminder.welcome_signature_id = 0;
                            vm.form.reminder.show_signature  = 0;
                            vm.form.reminder.signature_id  = 0;
                        }

                        if (vm.form.reminder.show_notification_signature == undefined) {
                            vm.form.reminder.show_notification_signature = 0;
                            vm.form.reminder.notification_signature_id = 0;
                        }

                        if (vm.form.public_settings.cover_type == undefined) {
                            vm.form.public_settings.cover_type = 2;
                        }
                        if (vm.form.public_settings.footer_has_facebook == undefined) {
                            vm.form.public_settings.footer_has_facebook = 1;
                            vm.form.public_settings.footer_has_twitter = 1;
                            vm.form.public_settings.footer_has_instagram = 1;
                            vm.form.public_settings.footer_has_linkedin = 1;
                            vm.form.public_settings.footer_has_youtube = 1;
                        }

                        if (vm.form.public_settings.seo_title == undefined) {
                            vm.form.public_settings.seo_title = '';
                            vm.form.public_settings.seo_keyword = '';
                            vm.form.public_settings.seo_desc = '';
                            vm.form.public_settings.seo_index = 0;
                            vm.form.public_settings.seo_share_title = '';
                            vm.form.public_settings.seo_share_desc = '';
                            vm.form.public_settings.seo_thumb = '';
                            vm.form.public_settings.seo_fav = '';
                        }

                        if (vm.form.recurring_settings.day_type == undefined) {
                            vm.form.recurring_settings.day_type = 'd';
                        }

                        if (vm.form.public_settings.thankyou_bgcolor == undefined) {
                            vm.form.public_settings.thankyou_bgcolor = '#faf2e4';
                            vm.form.public_settings.thankyou_textcolor = '#121525';
                        }

                        if (vm.form.public_settings.overlay_color == undefined) {
                            vm.form.public_settings.overlay_color = '#2c3e50';
                            vm.form.public_settings.overlay_opacity = 50;
                        }

                        if (vm.form.reminder.welcome_email_button_bg_color == undefined) {
                            vm.form.reminder.welcome_email_button_bg_color        = '#2c3e50';
                            vm.form.reminder.welcome_email_button_text_color      = '#FFF';
                            vm.form.reminder.email_button_bg_color                = '#2c3e50';
                            vm.form.reminder.email_button_text_color              = '#FFF';
                            vm.form.reminder.notification_email_button_bg_color   = '#2c3e50';
                            vm.form.reminder.notification_email_button_text_color = '#FFF';
                        }

                        vm.form.reminder.welcome_sending_method       = parseInt(vm.form.reminder.welcome_sending_method);
                        vm.form.reminder.sending_method               = parseInt(vm.form.reminder.sending_method);
                        vm.form.reminder.notification_sending_method  = parseInt(vm.form.reminder.notification_sending_method);

                        vm.welcomeEmail = {
                            defaultHandler: true,
                            subject: vm.form.reminder.welcome_email_subject,
                            email: vm.form.reminder.welcome_email_message,
                            is_email_logo: vm.form.reminder.show_welcome_email_logo,
                            is_signature: vm.form.reminder.welcome_show_signature,
                            signature_id: vm.form.reminder.welcome_signature_id,
                            show_email_logo: vm.form.reminder.show_welcome_email_logo,
                            email_logo: vm.form.reminder.welcome_logo_image,
                            email_button_bg_color: vm.form.reminder.welcome_email_button_bg_color ? vm.form.reminder.welcome_email_button_bg_color : '#2c3e50',
                            email_button_text_color: vm.form.reminder.welcome_email_button_text_color ? vm.form.reminder.welcome_email_button_text_color : '#FFF',
                            module_id: vm.habitTracking.id,
                        };

                        vm.reminderEmail = {
                            defaultHandler: true,
                            subject: vm.form.reminder.email_subject,
                            email: vm.form.reminder.email_message,
                            is_email_logo: vm.form.reminder.show_email_logo,
                            is_signature: vm.form.reminder.show_signature,
                            signature_id: vm.form.reminder.signature_id,
                            show_email_logo: vm.form.reminder.show_email_logo,
                            email_logo: vm.form.reminder.logo_image,
                            email_button_bg_color: vm.form.reminder.email_button_bg_color ? vm.form.reminder.email_button_bg_color : '#2c3e50',
                            email_button_text_color: vm.form.reminder.email_button_text_color ? vm.form.reminder.email_button_text_color : '#FFF',
                            module_id: vm.habitTracking.id,
                        };

                        vm.notificationEmail = {
                            defaultHandler: true,
                            subject: vm.form.reminder.notification_email_subject,
                            email: vm.form.reminder.notification_email_message,
                            is_email_logo: vm.form.reminder.show_notification_email_logo,
                            is_signature: vm.form.reminder.notification_show_signature,
                            signature_id: vm.form.reminder.notification_signature_id,
                            show_email_logo: vm.form.reminder.show_notification_email_logo,
                            email_logo: vm.form.reminder.notification_logo_image,
                            email_button_bg_color: vm.form.reminder.notification_email_button_bg_color ? vm.form.reminder.notification_email_button_bg_color : '#2c3e50',
                            email_button_text_color: vm.form.reminder.notification_email_button_text_color ? vm.form.reminder.notification_email_button_text_color : '#FFF',
                            module_id: vm.habitTracking.id,
                        };

                        vm.editedCategory      = 0;
                        vm.welcomeSmsText      = vm.form.reminder.welcome_sms_message;
                        vm.messageSmsText      = vm.form.reminder.sms_message;
                        vm.notificationSmsText = vm.form.reminder.notification_sms_message;

                        setTimeout( function () {
                            vm.reminderPreviewType = vm.form.reminder.welcome_sending_method == 2 ? 'sms' : 'email';
                        }, 10);

                        vm.resetCalendar();
                    }, 100);
                }
            },

            resetHabitTracking () {
                const vm = this;

                vm.form = {
                    name: '',
                    access_code: '',
                    url: '',
                    recurring_type: 3,
                    is_cumulative: 1,
                    recurring_settings: {
                        hours: 1,
                        day: 1,
                        days: 1,
                        weeks: 1,
                        months: 1,
                        week: 1,
                        month: 1,
                        years: 1,
                        end_type: 1,
                        end_occurrences: 1,
                        end_date: '',
                        week_name: 'mon',
                        weekdays: {
                            mon: false,
                            tue: false,
                            wed: false,
                            thu: false,
                            fri: false,
                            sat: false,
                            sun: true,
                        },
                        repeat: 1,
                        send_before: 5,
                        send_before_type: 3,
                        send_at: 7,
                        send_at_type: 'PM',
                        time_zone: vm.user ? vm.user.timezone : 'America/New_York',
                        day_type: 'd',
                    },
                    reminder:{
                        has_reminder: 1,
                        sending_method: 1,
                        throttle_for_user_tz: 1,
                        recipient_email:'',
                        recipient_mobile:'',
                        email_subject: '',
                        email_message: '',
                        sms_message: '',
                        media: [],
                        show_email_logo: false,
                        show_email_signature: false,
                        signature_id: 0,
                        logo_image: vm.user.photo,
                        welcome_sending_method: 1,
                        welcome_email_subject: '',
                        welcome_email_message: '',
                        welcome_sms_message: '',
                        show_welcome_email_logo: false,
                        welcome_logo_image: vm.user.photo,
                        welcome_media: [],
                        has_notification: 1,
                        notification_sending_method: 1,
                        notification_email_subject: '',
                        notification_email_message: '',
                        notification_sms_message: '',
                        show_notification_email_logo: false,
                        notification_logo_image: vm.user.photo,
                        notification_media: [],
                        notification_email_recipient: [vm.user.email],
                        notification_sms_recipient: [vm.user.sms_notification_number],
                        show_welcome_signature: 0,
                        welcome_signature_id: 0,
                        show_notification_signature: 0,
                        notification_signature_id: 0,
                        show_signature: 0,
                        welcome_reminder: 1,
                        reminder: 1,
                        welcome_email_button_bg_color: '#2c3e50',
                        welcome_email_button_text_color: '#FFF',
                        email_button_bg_color: '#2c3e50',
                        email_button_text_color: '#FFF',
                        notification_email_button_bg_color: '#2c3e50',
                        notification_email_button_text_color: '#FFF',
                    },
                    data_settings: {
                        total_submission: 6,
                        limited_submission: 0,
                        collapse_public_questions: 1,
                    },
                    public_settings: {
                        display_explainer_video: 0,
                        explainer_video_type: 0,
                        explainer_video: '',
                        explainer_embed: '',
                        display_company_branding: 1,
                        company_branding: vm.user.businessname,
                        display_cover: 0,
                        cover_type: 2,
                        headline: 'Time To Submit Your Habits',
                        subheadline: '',
                        display_logo: 0,
                        logo: '',
                        display_author: 0,
                        author:'',
                        display_header: 1,
                        display_footer: 0,
                        header_bgcolor: '#FFF',
                        header_textcolor: '#000',
                        submit_btn_text:  'Submit',
                        element_color: '#D4D4D4',
                        element_text_color: '#2C3E50',
                        submit_btn_textcolor: '#fff',
                        submit_btn_bgcolor: '#2c3e50',
                        footer_bgcolor: '#D4D4D4',
                        footer_textcolor:'#2C3E50',
                        cover_color:'#1976d2',
                        cover_textcolor:'#2C3E50',
                        cover: '',
                        small_cover: '',
                        seo_title: 'Join our Habit Challenge',
                        seo_keyword: 'Habits',
                        seo_desc: 'Whatever can be measured can be improved.',
                        seo_index: 0,
                        seo_share_title: 'Join our habit challenge',
                        seo_share_desc: 'Whatever can be measured can be improved',
                        seo_thumb: '',
                        seo_fav: '',
                        footer_has_facebook: 1,
                        footer_has_twitter: 1,
                        footer_has_instagram: 1,
                        footer_has_linkedin: 0,
                        footer_has_youtube: 0,
                        thankyou_bgcolor: '#FFFFFF',
                        thankyou_textcolor: '#2C3E50',
                        overlay_color: '#2c3e50',
                        overlay_opacity: 50,
                    },
                    habits: [
                        {
                            id: 0,
                            habit_tracking_category_id: 0,
                            name: '',
                            description: '',
                            requirements: '',
                            success_goal: 1,
                            submit_proof: false,
                        }
                    ],
                    categories: [
                        {
                            id: 0,
                            name: 'New Category'
                        }
                    ],
                    headline_setting: {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.25)',
                    },
                    subheadline_setting: {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.68)',
                    },
                    calculation_type: 0,
                };

                vm.generateDefaultMessage();
                vm.habitTrackingCategories = [{ id: 0, name: 'New Category', habit_tracking_id: vm.habitTracking.id, habits: [{}] }];
                vm.habitTrackingHabits = [{
                                                id: 0,
                                                habit_tracking_category_id: 0,
                                                name: '',
                                                description: '',
                                                requirements: '',
                                                success_goal: 1,
                                                submit_proof: false,
                                            }];

                vm.isNew = true;
                vm.headerTab = 'logo';

                vm.welcomeEmail = {
                    defaultHandler: true,
                    subject: vm.form.reminder.welcome_email_subject,
                    email: vm.form.reminder.welcome_email_message,
                    is_email_logo: vm.form.reminder.show_welcome_email_logo,
                    is_signature: vm.form.reminder.welcome_show_signature,
                    signature_id: vm.form.reminder.welcome_signature_id,
                    show_email_logo: vm.form.reminder.show_welcome_email_logo,
                    email_logo: vm.form.reminder.welcome_logo_image,
                    email_button_bg_color: vm.form.reminder.welcome_email_button_bg_color ? vm.form.reminder.welcome_email_button_bg_color : '#2c3e50',
                    email_button_text_color: vm.form.reminder.welcome_email_button_text_color ? vm.form.reminder.welcome_email_button_text_color : '#FFF',
                };

                vm.reminderEmail = {
                    defaultHandler: true,
                    subject: vm.form.reminder.email_subject,
                    email: vm.form.reminder.email_message,
                    is_email_logo: vm.form.reminder.show_email_logo,
                    is_signature: vm.form.reminder.show_signature,
                    signature_id: vm.form.reminder.signature_id,
                    show_email_logo: vm.form.reminder.show_email_logo,
                    email_logo: vm.form.reminder.logo_image,
                    email_button_bg_color: vm.form.reminder.email_button_bg_color ? vm.form.reminder.email_button_bg_color : '#2c3e50',
                    email_button_text_color: vm.form.reminder.email_button_text_color ? vm.form.reminder.email_button_text_color : '#FFF',
                };

                vm.notificationEmail = {
                    defaultHandler: true,
                    subject: vm.form.reminder.notification_email_subject,
                    email: vm.form.reminder.notification_email_message,
                    is_email_logo: vm.form.reminder.show_notification_email_logo,
                    is_signature: vm.form.reminder.notification_show_signature,
                    signature_id: vm.form.reminder.notification_signature_id,
                    show_email_logo: vm.form.reminder.show_notification_email_logo,
                    email_logo: vm.form.reminder.notification_logo_image,
                    email_button_bg_color: vm.form.reminder.notification_email_button_bg_color ? vm.form.reminder.notification_email_button_bg_color : '#2c3e50',
                    email_button_text_color: vm.form.reminder.notification_email_button_text_color ? vm.form.reminder.notification_email_button_text_color : '#FFF',
                };
            },

            toggleStep (step) {
                const vm = this;

                if (vm.isNew) {
                    if (step <= vm.lastStep) {
                        vm.habitStep = step;
                    }
                } else {
                    vm.habitStep = step;
                }
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;

                params.reminder.welcome_email_subject         = vm.welcomeEmail.subject;
                params.reminder.welcome_email_message         = vm.welcomeEmail.email;
                params.reminder.welcome_show_signature        = vm.welcomeEmail.is_signature;
                params.reminder.welcome_signature_id          = vm.welcomeEmail.signature_id;
                params.reminder.show_welcome_email_logo       = vm.welcomeEmail.show_email_logo;
                params.reminder.welcome_logo_image            = vm.welcomeEmail.email_logo;
                params.reminder.email_subject                 = vm.reminderEmail.subject;
                params.reminder.email_message                 = vm.reminderEmail.email;
                params.reminder.show_signature                = vm.reminderEmail.is_signature;
                params.reminder.signature_id                  = vm.reminderEmail.signature_id;
                params.reminder.show_email_logo               = vm.reminderEmail.show_email_logo;
                params.reminder.logo_image                    = vm.reminderEmail.email_logo;
                params.reminder.notification_email_subject    = vm.notificationEmail.subject;
                params.reminder.notification_email_message    = vm.notificationEmail.email;
                params.reminder.notification_show_signature   = vm.notificationEmail.is_signature;
                params.reminder.notification_signature_id     = vm.notificationEmail.signature_id;
                params.reminder.show_notification_email_logo  = vm.notificationEmail.show_email_logo;
                params.reminder.notification_logo_image       = vm.notificationEmail.email_logo;

                params.reminder.welcome_email_button_bg_color         = vm.welcomeEmail.email_button_bg_color;
                params.reminder.welcome_email_button_text_color       = vm.welcomeEmail.email_button_text_color;
                params.reminder.email_button_bg_color                 = vm.reminderEmail.email_button_bg_color;
                params.reminder.email_button_text_color               = vm.reminderEmail.email_button_text_color;
                params.reminder.notification_email_button_bg_color    = vm.notificationEmail.email_button_bg_color;
                params.reminder.notification_email_button_text_color  = vm.notificationEmail.email_button_text_color;

                params.setFieldError = setFieldError;
                params.is_new = vm.isNew;

                const isIntervalChanged = vm.checkIfIntervalChanged(params);

                if (vm.habitTracking.assign.length > 0 && vm.habitTracking.completed_contacts_count > 0 && vm.habitStep == 1 && isIntervalChanged) {
                    const options = Helper.swalWarningOptions('Alert!', `There are already clients enrolled in this scenario. Changing the collection interval will not impact reminders for these active clients but will apply to any future clients assigned to this scenario.`);

                    Swal.fire(options).then((result) => {
                        if (result.isConfirmed) {
                            vm.saveHabitTracking(params).then((result) => {
                                if (result && vm.habitStep < 5) {
                                    if (vm.switchStep) {
                                        vm.habitStep  = vm.habitStep + 1;
                                        vm.lastStep   = vm.habitStep;
                                    }
                                } else if (result && vm.habitStep == 5) {
                                    if (vm.switchStep) {
                                        vm.closeModal();
                                    }
                                }
                                vm.switchStep = true;
                            });
                        }
                    });
                } else {
                    vm.saveHabitTracking(params).then((result) => {
                        if (result && vm.habitStep < 5) {
                            if (vm.switchStep) {
                                vm.habitStep  = vm.habitStep + 1;
                                vm.lastStep   = vm.habitStep;
                            }
                        } else if (result && vm.habitStep == 5) {
                            if (vm.switchStep) {
                                vm.closeModal();
                            }
                        }
                        vm.switchStep = true;
                    });
                }
            },

            checkIfIntervalChanged (form) {
                const vm = this;

                if (vm.objectsMatch(vm.habitTracking.recurring_details, form.recurring_details)) {
                    return true;
                } else if (vm.objectsMatch(vm.habitTracking.recurring_settings, form.recurring_settings)) {
                    return true;
                } else if (vm.habitTracking.recurring_type != form.recurring_type) {
                    return true;
                }

                return false;
            },

            objectsMatch(obj1, obj2) {
                return JSON.stringify(obj1) !== JSON.stringify(obj2);
            },

            handleWeekdaysChange (e) {
                const vm = this;
                const week = e.target.getAttribute('data-day');

                vm.form.recurring_settings.weekdays.mon = false;
                vm.form.recurring_settings.weekdays.tue = false;
                vm.form.recurring_settings.weekdays.wed = false;
                vm.form.recurring_settings.weekdays.thu = false;
                vm.form.recurring_settings.weekdays.fri = false;
                vm.form.recurring_settings.weekdays.sat = false;
                vm.form.recurring_settings.weekdays.sun = false;
                vm.form.recurring_settings.weekdays[week] = true;
            },

            handleWelcomeDefaultEmail () {
                const vm = this;

                vm.form.reminder.welcome_email_subject  = vm.getDefaultMessage('email-subject', 'welcome-reminder');
                vm.form.reminder.welcome_email_message  = vm.getDefaultMessage('email-content', 'welcome-reminder');

                vm.welcomeEmail.subject  = vm.form.reminder.welcome_email_subject;
                vm.welcomeEmail.email    = vm.form.reminder.welcome_email_message;
            },

            handleWelcomeDefaultSms () {
                const vm = this;

                vm.form.reminder.welcome_sms_message  = vm.getDefaultMessage('sms-content', 'welcome-reminder');
            },

            handleReminderDefaultEmail () {
                const vm = this;

                vm.form.reminder.email_subject  = vm.getDefaultMessage('email-subject', 'reminder');
                vm.form.reminder.email_message  = vm.getDefaultMessage('email-content', 'reminder');

                vm.reminderEmail.subject  = vm.form.reminder.email_subject;
                vm.reminderEmail.email    = vm.form.reminder.email_message;
            },

            handleReminderDefaultSms () {
                const vm = this;

                vm.form.reminder.sms_message  = vm.getDefaultMessage('sms-content', 'reminder');
            },

            handleNotificationDefaultEmail () {
                const vm = this;

                vm.form.reminder.notification_email_subject  = vm.getDefaultMessage('email-subject', 'notification-reminder');
                vm.form.reminder.notification_email_message  = vm.getDefaultMessage('email-content', 'notification-reminder');

                vm.notificationEmail.subject  = vm.form.reminder.notification_email_subject;
                vm.notificationEmail.email    = vm.form.reminder.notification_email_message;
            },

            handleNotificationDefaultSms () {
                const vm = this;

                vm.form.reminder.notification_sms_message  = vm.getDefaultMessage('sms-content', 'notification-reminder');
            },

            handleNotificationEmailRecipient (recipient) {
                const vm = this;

                vm.form.reminder.notification_email_recipient = recipient;
            },

            handleNotificationSMSRecipient (recipient) {
                const vm = this;

                vm.form.reminder.notification_sms_recipient = recipient;
            },

            getDefaultMessage (type, section) {
                if (type == 'email-subject' && section == 'reminder') {
                    return "It's time to submit your habits {{first_name}}!";
                } else if (type == 'email-content' && section == 'reminder') {
                    let email_message = "<p>Hi {{first_name}}!</p>";
                        email_message += "<p>{{owner_first_name}} is requesting that you submit your habits.</p>";
                        email_message += "<p>We can only improve what we can measure!</p>";
                        email_message += "<p>{{habit_tracking_button}}</p>";
                        email_message += "<p>You can use your member ID <b>{{member_id}}</b> to submit your form.</p>";
                        email_message += "<p>Sincerely,</p>";
                        email_message += "<p>Team {{company_name}}</p>";

                    return email_message;
                } else if (type == 'sms-content' && section == 'reminder') {
                    let sms_message = "{{owner_first_name}} is requesting that you submit your habits.\r\n";
                        sms_message += "{{habit_tracking_link}}\r\n";
                        sms_message += "You can use your member ID {{member_id}} to submit your form.\r\n";
                        sms_message += "Team {{company_name}}";

                    return sms_message;
                } else if (type == 'email-subject' && section == 'welcome-reminder') {
                    return "You have been enrolled in habit tracking {{first_name}}";
                } else if (type == 'email-content' && section == 'welcome-reminder') {
                    let email_message = "<p>Hi {{first_name}}!</p>";
                        email_message += "<p>You have been enrolled in habit tracking by {{company_name}}! To get started click the button below to see the habits your coach wants you to build.</p>";
                        email_message += "<p>{{habit_tracking_goal_button}}</p>";
                        email_message += "<p>You can use your member ID {{member_id}} to submit your Habit tracking form.</p>";
                        email_message += "<p>You will be reminded via email or SMS when it's time to track your habits.</p>";
                        email_message += "<p>Sincerely,</p>";
                        email_message += "<p>Team {{company_name}}</p>";

                    return email_message;
                } else if (type == 'sms-content' && section == 'welcome-reminder') {
                    let sms_message = "Hi {{first_name}},\r\n";
                        sms_message += "You have been enrolled in habit tracking by {{company_name}}! To get started click the link below to see the habits your coach wants you to build.\r\n";
                        sms_message += "{{habit_tracking_goal_link}}\r\n";
                        sms_message += "You can use your member ID {{member_id}} to submit your Habit tracking form.\r\n";
                        sms_message += "You will be reminded via email or SMS when it's time to track your habits.\r\n";
                        sms_message += "Sincerely, \r\n";
                        sms_message += "Team {{company_name}}";

                    return sms_message;
                } else if (type == 'email-subject' && section == 'notification-reminder') {
                    return "Habits Submitted!";
                } else if (type == 'email-content' && section == 'notification-reminder') {
                    let email_message = "<p>Hi {{company_owner}}!</p>";
                        email_message += "<p>{{full_name}} has just submitted habits to {{habit_tracking_name}} habit scenario.</p>";
                        email_message += "<p>Click the link below to view them now.</p>";
                        email_message += "<p>{{habit_tracking_response_link}}</p>";

                    return email_message;
                } else if (type == 'sms-content' && section == 'notification-reminder') {
                    let sms_message = "Hi {{company_owner}},\r\n";
                        sms_message += "{{full_name}} has just submitted habit to {{habit_tracking_name}} habit scenario.\r\n";
                        sms_message += "Click the link below to view them now.\r\n";
                        sms_message += "{{habit_tracking_response_link}}";

                    return sms_message;
                }

                return '';
            },

            generateDefaultMessage () {
                const vm = this;

                vm.form.reminder.email_subject  = '';
                vm.form.reminder.email_message  = '';
                vm.form.reminder.sms_message    = '';
                vm.form.reminder.welcome_email_subject  = '';
                vm.form.reminder.welcome_email_message  = '';
                vm.form.reminder.welcome_sms_message    = '';
                vm.form.reminder.notification_email_subject  = '';
                vm.form.reminder.notification_email_message  = '';
                vm.form.reminder.notification_sms_message    = '';

                vm.form.reminder.email_subject               = vm.getDefaultMessage('email-subject', 'reminder');
                vm.form.reminder.email_message               = vm.getDefaultMessage('email-content', 'reminder');
                vm.form.reminder.sms_message                 = vm.getDefaultMessage('sms-content', 'reminder');
                vm.form.reminder.welcome_email_subject       = vm.getDefaultMessage('email-subject', 'welcome-reminder');
                vm.form.reminder.welcome_email_message       = vm.getDefaultMessage('email-content', 'welcome-reminder');
                vm.form.reminder.welcome_sms_message         = vm.getDefaultMessage('sms-content', 'welcome-reminder');
                vm.form.reminder.notification_email_subject  = vm.getDefaultMessage('email-subject', 'notification-reminder');
                vm.form.reminder.notification_email_message  = vm.getDefaultMessage('email-content', 'notification-reminder');
                vm.form.reminder.notification_sms_message    = vm.getDefaultMessage('sms-content', 'notification-reminder');
            },

            handleCategoryDelete (id, index) {
                const vm = this;
                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this category`, 'Delete');
                options.preConfirm = function () {
                                        return vm.deleteCategory(id).then((result) => {
                                            if (result) {
                                                vm.habitTrackingCategories.splice(index, 1);
                                            }
                                        });
                                    };

                Swal.fire(options);
            },

            handleCategorySort (e) {
                const vm = this;
                const ids = vm.habitTrackingCategories.map((cat) => { return cat.id });

                vm.sortCategory({ ids });
            },

            handleCategoryHabitSort (index) {
                const vm    = this;
                const ids   = vm.habitTrackingCategories[index].habits.map((habit) => { return habit.id });

                vm.sortCategoryHabits({ ids });
            },

            handleCategoryHabitToggle (category, index) {
                const vm = this;

                vm.categoryHabitListDialog  = true;
                vm.categoryHabitListIndex   = index;

                if (category) {
                    vm.selectedCategory = category;
                }

                let element = document.getElementById(`category-box-${index}`);
                let allParentElement = document.querySelectorAll('.custom_list li');

                for(let i = 0; i < allParentElement.length; i++){
                    allParentElement[i].classList.remove('show');
                }

                if (element) {
                    element.classList.add('show');
                }
            },

            handleHabitsToggle (ev) {
                const parentElement     = ev.currentTarget.closest('li');
                const allParentElement  = document.querySelectorAll('.custom_list li');

                if (parentElement.classList.contains('show')) {
                    parentElement.classList.remove('show');
                } else {
                    for(let i = 0; i < allParentElement.length; i++){
                        allParentElement[i].classList.remove('show');
                    }

                    parentElement.classList.add('show');
                }
            },

            handleHabitsEdit (ev, habitId) {
                const vm                = this;
                const parentElement     = ev.currentTarget.closest('.habit_item');
                const allParentElement  = document.querySelectorAll('.custom_list li .habit_item');

                if (parentElement.classList.contains('open')) {
                    parentElement.classList.remove('open');
                } else {
                    for(let i = 0; i < allParentElement.length; i++){
                        allParentElement[i].classList.remove('open');
                    }

                    parentElement.classList.add('open');
                }

                vm.editedHabitId = habitId;
            },

            updateHabitCategory (params, index) {
                const vm = this;
                const form = vm.$refs['habit-wizard-form'];

                if (form) {
                    params.setFieldError = form.setFieldError;
                    params.error_index = params.id;
                }

                if (params.name.length) {
                    vm.saveHabitCategory(params).then((result) => {
                        if (result) {
                            if (index >= 0) {
                                vm.habitTrackingCategories[index] = result.category;
                            } else {
                                vm.habitTrackingCategories.push(result.category);
                            }

                            form.setFieldError(`category_${params.id}`, '');
                            vm.editedCategory = 0;
                        }
                    });
                } else {
                    Toastr.error('', 'The category name field is required');
                }
            },

            handleCopy (string) {
                copyText(string, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy link!');
                    } else {
                        Toastr.success('Link copied successfully!');
                    }
                })
            },

            goTo (refName) {
                const vm = this;

                if (vm.isNew) {
                    return false;
                }

                setTimeout(function () {
                    if ( vm.habitStep != 2 && vm.habitStep != 3 ) {
                        setTimeout(function () {
                            let element = vm.$refs[refName];

                            setTimeout(function() {
                                if (element) {
                                    let top = element.offsetTop;
                                    document.querySelector('.preview_content .content_area').scrollTo(0, top);
                                }
                            }, 100);
                        }, 10);
                    } else {
                        vm.$refs['preview-content'].goTo(refName);
                    }
                }, 10);
            },

            handleAddCategory () {
                const vm = this;

                vm.updateHabitCategory({ id: '', name: 'New Category', habit_tracking_id: vm.habitTracking.id });
            },

            handleAddHabit (categoryId, index) {
                const vm = this;
                const habit = {
                                  id: 0,
                                  habit_tracking_id: vm.habitTracking.id,
                                  habit_tracking_category_id: categoryId,
                                  name: '',
                                  description: '',
                                  requirements: '',
                                  success_goal: 1,
                                  submit_proof: false,
                              };

                vm.addHabitLoader.push(categoryId);

                vm.createHabit(habit).then((result) => {
                    if (result) {
                        vm.activeHabit = vm.habitTrackingHabits.length;
                        vm.habitDetail = true;
                        vm.habitTrackingCategories[index].habits.push(result);
                        vm.addHabitLoader = vm.addHabitLoader.filter(v => v !== categoryId);

                        vm.handleCategoryHabitToggle(categoryId, index);
                    }
                }).catch((err) => {
                    vm.addHabitLoader = vm.addHabitLoader.filter(v => v !== categoryId);
                });
            },

            handleSuccessGoalIncrementDecrement (habit, operator) {
                const vm = this;

                if (operator == '+' && habit.success_goal < 7) {
                    habit.success_goal = habit.success_goal + 1;
                    vm.habitUpdateWatcher = true;
                } else if (operator == '-' && habit.success_goal > 1) {
                    habit.success_goal = habit.success_goal - 1;
                    vm.habitUpdateWatcher = true;
                }
            },

            handleCategoryHabitAutosave () {
                const vm = this;
                const form = vm.$refs['habit-wizard-form'];

                if (!vm.habitUpdateWatcher) {
                    form.validate().then((result) => {
                        if (result.valid) {
                            const params = { id: vm.habitTracking.id, categories: vm.habitTrackingCategories };

                            vm.saveHabitTrackingHabits(params).then((resp) => {
                                vm.getHabitCategories({ habit_tracking_id: vm.habitTracking.id });
                                vm.habitTrackingHabits      = resp.habits;
                                vm.habitTrackingCategories  = resp.categories;
                                vm.refreshHabitTracking(vm.habitTracking.id);
                            });
                        }
                    });
                }
            },

            handleDeleteCategoryHabit (cindex, hindex, id) {
                const vm = this;
                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this habit question`, 'Delete');
                      options.preConfirm = function () {
                                                return vm.deleteHabit({ id }).then((result) => {
                                                    if (result) {
                                                        vm.habitTrackingCategories[cindex].habits.splice(hindex, 1);
                                                        vm.getHabitCategories({ habit_tracking_id: vm.habitTracking.id });
                                                        vm.refreshHabitTracking(vm.habitTracking.id);
                                                    }
                                                });
                                            };

                Swal.fire(options);
            },

            handleDeleteHabitTracking (ids) {
                const vm  = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this habit scenario`, 'Delete');
                      options.preConfirm = function () {
                                                return vm.deleteHabitTracking({ ids }).then((result) => {
                                                    if (result) {
                                                        vm.closeModal();
                                                        vm.resetForm();

                                                        if (vm.$parent.$parent.refreshHabitTracking) {
                                                            vm.$parent.$parent.refreshHabitTracking();
                                                        } else {
                                                            vm.$parent.refreshHabitTracking();
                                                        }
                                                    }
                                                });
                                            };

                Swal.fire(options);
            },

            setting (refName) {
                const vm = this;

                if (refName === 'hdrsetting') {
                    vm.habitStep = 3;
                } else if (refName === 'cvrsetting') {
                    vm.habitStep = 3;
                } else if (refName === 'ftrsetting') {
                    vm.habitStep = 3;
                } else if (refName === 'subbtnsetting') {
                    vm.habitStep = 3;
                } else if (refName === 'catsetting') {
                    vm.habitStep = 2;
                }

                if (refName !== 'catsetting') {
                    setTimeout(function () {
                        let element = vm.$refs[refName];

                        setTimeout(function () {
                            if (element) {
                                let top = element.offsetTop;
                                document.querySelector('.global_setting .tabs_content').scrollTo(0, top);
                            }
                        }, 100);
                    }, 100);
                }
            },

            updateSmsText () {
                const vm = this;

                const welcomeSms      = vm.$refs['welcome-sms-component'];
                const messageSms      = vm.$refs['message-sms-component'];
                const notificationSms = vm.$refs['notification-sms-component'];

                if (welcomeSms) {
                    vm.welcomeSmsText = welcomeSms.smsMedia && welcomeSms.smsMedia.media && welcomeSms.smsMedia.media[0] ? vm.form.reminder.welcome_sms_message + '\n\n' + welcomeSms.smsMedia.media[0].toString() : vm.form.reminder.welcome_sms_message;
                }

                if (messageSms) {
                    vm.messageSmsText = messageSms.smsMedia && messageSms.smsMedia.media && messageSms.smsMedia.media[0] ? vm.form.reminder.sms_message + '\n\n' + messageSms.smsMedia.media[0].toString() : vm.form.reminder.sms_message;
                }

                if (notificationSms) {
                    vm.notificationSmsText = notificationSms.smsMedia && notificationSms.smsMedia.media && notificationSms.smsMedia.media[0] ? vm.form.reminder.notification_sms_message + '\n\n' + notificationSms.smsMedia.media[0].toString() : vm.form.reminder.notification_sms_message;
                }
            },

            handleReminderPreviewType(tab) {
                const vm = this;

                if (tab == 'welcome') {
                    vm.reminderTab         = 'welcome';
                    vm.reminderPreviewType = vm.form.reminder.welcome_sending_method == 2 ? 'sms' : 'email';
                    vm.welcomeSmsText      = vm.form.reminder && vm.form.reminder.welcome_media ? vm.form.reminder.welcome_sms_message + '\n\n' + vm.form.reminder.welcome_media : vm.form.reminder.welcome_sms_message;
                } else if (tab == 'reminders') {
                    vm.reminderTab         = 'reminders';
                    vm.reminderPreviewType = vm.form.reminder.sending_method == 2 ? 'sms' : 'email';
                    vm.messageSmsText      = vm.form.reminder && vm.form.reminder.sms_message ? vm.form.reminder.sms_message + '\n\n' + vm.form.reminder.media : vm.form.reminder.sms_message;
                } else if (tab == 'admin') {
                    vm.reminderTab         = 'admin';
                    vm.reminderPreviewType = vm.form.reminder.notification_sending_method == 2 ? 'sms' : 'email';
                    vm.notificationSmsText = vm.form.reminder && vm.form.reminder.notification_sms_message ? vm.form.reminder.notification_sms_message + '\n\n' + vm.form.reminder.notification_media : vm.form.reminder.notification_sms_message;
                }
            },

            toggleSectionBar () {
                const leftSidebar = document.querySelector('.global_setting');

                if (leftSidebar.classList.contains('show_m_sidebar')) {
                    leftSidebar.classList.remove('show_m_sidebar');
                } else {
                    leftSidebar.classList.add('show_m_sidebar');
                }
            },

            resizeEventHandler () {
                const vm = this;
                let screenWidth = window.innerWidth;

                if (screenWidth < 768) {
                    vm.preview = false;
                } else {
                    vm.preview = true;
                }
            },

            toggleSection (ev) {
                const vm = this;
                let parentElm = ev.currentTarget.closest('.section_content');

                if (parentElm.classList.contains('show')) {
                    parentElm.classList.remove('show');
                    vm.toggleAll = 1;
                } else {
                    parentElm.classList.add('show');
                    vm.toggleAll = 0;
                }
            },

            openAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for(let i = 0; i < allParentElm.length; i++){
                    allParentElm[i].classList.add('show');
                }

                vm.toggleAll = 0;
            },

            closeAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for (let i = 0; i < allParentElm.length; i++) {
                    allParentElm[i].classList.remove('show');
                }

                vm.toggleAll = 1;
            },

            resetToDefaultDataValues () {
                const vm = this;

                const formData = {
                                    habitStep: 1,
                                    preview: true,
                                    toggleAll: 1,
                                    form: {
                                        name: '',
                                        access_code: '',
                                        url: '',
                                        recurring_type: 3,
                                        is_cumulative: 1,
                                        recurring_settings: {
                                            hours: 1,
                                            day: 1,
                                            days: 1,
                                            weeks: 1,
                                            months: 1,
                                            week: 1,
                                            month: 1,
                                            years: 1,
                                            end_type: 1,
                                            end_occurrences: 1,
                                            end_date: '',
                                            week_name: 'mon',
                                            weekdays: {
                                                mon: false,
                                                tue: false,
                                                wed: false,
                                                thu: false,
                                                fri: false,
                                                sat: false,
                                                sun: true,
                                            },
                                            repeat: 1,
                                            send_before: 5,
                                            send_before_type: 3,
                                            send_at: 7,
                                            send_at_type: 'PM',
                                            time_zone: this.user ? this.user.timezone : '',
                                            day_type: 'd',
                                        },
                                        reminder:{
                                            has_reminder: 1,
                                            sending_method: 1,
                                            throttle_for_user_tz: 1,
                                            recipient_email:'',
                                            recipient_mobile:'',
                                            email_subject: '',
                                            email_message: '',
                                            sms_message: '',
                                            media: [],
                                            show_email_logo: false,
                                            show_email_signature: false,
                                            signature_id: 0,
                                            logo_image: '',
                                            welcome_sending_method: 1,
                                            welcome_email_subject: '',
                                            welcome_email_message: '',
                                            welcome_sms_message: '',
                                            show_welcome_email_logo: false,
                                            welcome_logo_image: '',
                                            welcome_media: [],
                                            has_notification: 1,
                                            notification_sending_method: 1,
                                            notification_email_subject: '',
                                            notification_email_message: '',
                                            notification_sms_message: '',
                                            show_notification_email_logo: false,
                                            notification_logo_image: '',
                                            notification_media: [],
                                            notification_email_recipient: [this.user ? this.user.email : ''],
                                            notification_sms_recipient: [this.user ? this.user.sms_notification_number : ''],
                                            show_welcome_signature: 0,
                                            welcome_signature_id: 0,
                                            show_signature: 0,
                                            welcome_reminder: 1,
                                            reminder: 1,
                                            welcome_email_button_bg_color: '',
                                            welcome_email_button_text_color: '',
                                            email_button_bg_color: '',
                                            email_button_text_color: '',
                                            notification_email_button_bg_color: '',
                                            notification_email_button_text_color: '',
                                        },
                                        data_settings: {
                                            total_submission: 6,
                                            limited_submission: 0,
                                            collapse_public_questions: 1,
                                        },
                                        public_settings: {
                                            display_explainer_video: 0,
                                            explainer_video_type: 0,
                                            explainer_video: '',
                                            explainer_embed: '',
                                            display_company_branding: 1,
                                            company_branding: '',
                                            display_cover: 0,
                                            cover_type: 2,
                                            headline: 'Time To Submit Your Habits',
                                            subheadline: '',
                                            display_logo: 0,
                                            logo: '',
                                            display_author: 0,
                                            author:'',
                                            display_header: 1,
                                            display_footer: 0,
                                            header_bgcolor: '#FFF',
                                            header_textcolor: '#000',
                                            submit_btn_text:  'Submit',
                                            element_color:  '#D4D4D4',
                                            element_text_color: '#2C3E50',
                                            submit_btn_textcolor: '#fff',
                                            submit_btn_bgcolor: '#2c3e50',
                                            footer_bgcolor: '#D4D4D4',
                                            footer_textcolor: '#2C3E50',
                                            cover_color:  '#1976d2',
                                            cover_textcolor:  '#2C3E50',
                                            cover: '',
                                            small_cover: '',
                                            seo_title: 'Join our Habit Challenge',
                                            seo_keyword: 'Habits',
                                            seo_desc: 'Whatever can be measured can be improved.',
                                            seo_index: 0,
                                            seo_share_title: 'Join our habit challenge',
                                            seo_share_desc: 'Whatever can be measured can be improved',
                                            seo_thumb: '',
                                            seo_fav: '',
                                            footer_has_facebook: 1,
                                            footer_has_twitter: 1,
                                            footer_has_instagram: 1,
                                            footer_has_linkedin: 0,
                                            footer_has_youtube: 0,
                                            thankyou_bgcolor: '#FFFFFF',
                                            thankyou_textcolor: '#2C3E50',
                                            overlay_color: '#2c3e50',
                                            overlay_opacity: 50,
                                        },
                                        habits: [
                                            {
                                                id: 0,
                                                habit_tracking_category_id: 0,
                                                name: '',
                                                description: '',
                                                requirements: '',
                                                success_goal: 1,
                                                submit_proof: false,
                                            }
                                        ],
                                        categories: [
                                            {
                                                id: 0,
                                                name: 'New Category',
                                                habit_tracking_id: 0,
                                            }
                                        ],
                                        headline_setting: {},
                                        subheadline_setting: {},
                                        calculation_type: 0,
                                    },
                                    months: {
                                        1: 'January',
                                        2: 'February',
                                        3: 'March',
                                        4: 'April',
                                        5: 'May',
                                        6: 'June',
                                        7: 'July',
                                        8: 'August',
                                        9: 'September',
                                        10: 'October',
                                        11: 'November',
                                        12: 'December'
                                    },
                                    reminderOption: [{
                                            reminder_value: '',
                                            reminder_type: 'on time',
                                        },
                                        {
                                            reminder_value: 5,
                                            reminder_type: 'minutes',
                                        },
                                        {
                                            reminder_value: 10,
                                            reminder_type: 'minutes',
                                        },
                                        {
                                            reminder_value: 20,
                                            reminder_type: 'minutes',
                                        },
                                        {
                                            reminder_value: 30,
                                            reminder_type: 'minutes',
                                        },
                                        {
                                            reminder_value: 1,
                                            reminder_type: 'hour',
                                        },
                                        {
                                            reminder_value: 2,
                                            reminder_type: 'hours',
                                        },
                                        {
                                            reminder_value: 5,
                                            reminder_type: 'hours',
                                        },
                                        {
                                            reminder_value: 10,
                                            reminder_type: 'hours',
                                        },
                                        {
                                            reminder_value: '',
                                            reminder_type: 'custom',
                                        }
                                    ],
                                    habitTrackingCategories: [],
                                    habitTrackingHabits: [],
                                    isNew: false,
                                    hours: {
                                        mode: 'single',
                                        value: [7],
                                        options: [
                                            { value: 1, label: '1' },
                                            { value: 2, label: '2' },
                                            { value: 3, label: '3' },
                                            { value: 4, label: '4' },
                                            { value: 5, label: '5' },
                                            { value: 6, label: '6' },
                                            { value: 7, label: '7' },
                                            { value: 8, label: '8' },
                                            { value: 9, label: '9' },
                                            { value: 10, label: '10' },
                                            { value: 11, label: '11' },
                                            { value: 12, label: '12' },
                                        ],
                                        createTag: true
                                    },
                                    clock: {
                                        mode: 'single',
                                        value: ['AM'],
                                        options: [
                                            { value: 'AM', label: 'AM' },
                                            { value: 'PM', label: 'PM' }
                                        ],
                                        createTag: true
                                    },
                                    intervalTimeone: {},
                                    weekdays: [
                                        'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'
                                    ],
                                    reminderTab: 'welcome',
                                    welcomeEmail: {
                                        defaultHandler: true,
                                        subject: '',
                                        email: '',
                                        is_email_logo: 0,
                                        is_signature: 0,
                                        signature_id: 0,
                                        show_email_logo: 0,
                                        email_logo: '',
                                    },
                                    reminderEmail: {
                                        defaultHandler: true,
                                        subject: '',
                                        email: '',
                                        is_email_logo: 0,
                                        is_signature: 0,
                                        signature_id: 0,
                                        show_email_logo: 0,
                                        email_logo: '',
                                    },
                                    notificationEmail: {
                                        defaultHandler: true,
                                        subject: '',
                                        email: '',
                                        is_email_logo: 0,
                                        is_signature: 0,
                                        signature_id: 0,
                                        show_email_logo: 0,
                                        email_logo: '',
                                    },
                                    welcome_sms_media: [],
                                    reminder_sms_media: [],
                                    notification_sms_media: [],
                                    activeHabit: 0,
                                    headerTab: 'logo',
                                    headlineSetting: false,
                                    subheadlineSetting: false,
                                    calendarAttributes: [],
                                    editedCategory: 0,
                                    categoryHabitListDialog: false,
                                    categoryHabitListIndex: 0,
                                    selectedCategory: {},
                                    addHabitLoader: [],
                                    habitUpdateWatcher: false,
                                    reminderPreviewType: 'email',
                                    whiteLabel: {},
                                    previewReminder: false,
                                    fullPreview: false,
                                    categoryHabitPreview: false,
                                    selectedCategoryHabit: {},
                                    env: {},
                                    welcomeSmsText: '',
                                    messageSmsText: '',
                                    notificationSmsText: '',
                                    previewPage: 'habit',
                                    switchStep: true,
                                    editedHabitId: 0,
                                    lastStep: 0,
                                };

                Object.assign(vm.$data, formData);
            },

            handleApplySavedStyle (section = 'footer') {
                const vm = this;

                if (vm.habitTrackingDefaultContent && vm.habitTrackingDefaultContent.public_settings && vm.form.public_settings) {
                    if (section == 'footer') {
                        vm.form.public_settings.display_footer       = vm.habitTrackingDefaultContent.public_settings.display_footer ? 1 : 0;
                        vm.form.public_settings.footer_company       = vm.habitTrackingDefaultContent.public_settings.footer_company;
                        vm.form.public_settings.footer_bgcolor       = vm.habitTrackingDefaultContent.public_settings.footer_bgcolor;
                        vm.form.public_settings.footer_policy        = vm.habitTrackingDefaultContent.public_settings.footer_policy;
                        vm.form.public_settings.footer_terms         = vm.habitTrackingDefaultContent.public_settings.footer_terms;
                        vm.form.public_settings.footer_textcolor     = vm.habitTrackingDefaultContent.public_settings.footer_textcolor;
                        vm.form.public_settings.footer_facebook      = vm.habitTrackingDefaultContent.public_settings.footer_facebook;
                        vm.form.public_settings.footer_twitter       = vm.habitTrackingDefaultContent.public_settings.footer_twitter;
                        vm.form.public_settings.footer_instagram     = vm.habitTrackingDefaultContent.public_settings.footer_instagram;
                        vm.form.public_settings.footer_linkedin      = vm.habitTrackingDefaultContent.public_settings.footer_linkedin;
                        vm.form.public_settings.footer_youtube       = vm.habitTrackingDefaultContent.public_settings.footer_youtube;
                        vm.form.public_settings.footer_has_instagram = vm.habitTrackingDefaultContent.public_settings.footer_has_instagram ? 1 : 0;
                        vm.form.public_settings.footer_has_facebook  = vm.habitTrackingDefaultContent.public_settings.footer_has_facebook ? 1 : 0;
                        vm.form.public_settings.footer_has_twitter   = vm.habitTrackingDefaultContent.public_settings.footer_has_twitter ? 1 : 0;
                        vm.form.public_settings.footer_has_linkedin  = vm.habitTrackingDefaultContent.public_settings.footer_has_linkedin ? 1 : 0;
                        vm.form.public_settings.footer_has_youtube   = vm.habitTrackingDefaultContent.public_settings.footer_has_youtube ? 1 : 0;
                    }
                }
            },

            handleApplySavedStylingWarning () {
                const vm = this;

                if (vm.form.apply_saved_styling) {
                    const options = Helper.swalConfirmOptions('Are you sure?', 'Enabling this will update your header, cover, footer, and more with saved styles, and this action cannot be undone.');

                    Swal.fire(options).then((result) => {
                        if (result.isDismissed) {
                            vm.form.apply_saved_styling = 0;
                        }
                    });
                }
            },
        },
    }
</script>

<style scoped>
    .habit-wizard-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .progress_item {
        border-radius: 6px;
        max-width: 400px;
        margin: 0 15px;
        border: 1px solid #e0e0e0;
        background: #fff;
    }

    .progress_item .item_img {
        line-height: 0;
    }

    .progress_item .item_img img {
        width: 100%;
        height: 150px;
        object-fit: cover;
        border-radius: 6px 6px 0 0;
    }

    .progress_item .item_ttl {
        font-size: 14px;
        line-height: 18px;
        color: #121525;
        font-weight: 400;
        padding: 12px 20px 0 20px;
        margin-bottom: 12px;
        text-align: left;
        display: block;
        text-decoration: none;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .progress_item .item_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 20px 20px;
    }

    .progress_item .item_info .member {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    .progress_item .item_info img {
        margin-right: 5px;
        max-height: 13px;
        width: auto;
    }

    .step_name {
        display: flex;
        align-items: center;
    }

    .step_name h3 {
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #121525;
        padding: 5px 0;
        transition: all 0.3s ease-in-out;
    }

    .step_wpr h3 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #32373b;
        padding: 5px 0;
    }

    .track_list {
        padding: 15px;
    }

    .track_list li {
        padding: 0 20px 20px 40px;
        position: relative;
    }

    .track_list li:not(:last-child):after {
        position: absolute;
        content: '';
        left: 14px;
        top: 0;
        bottom: 0;
        border-left: 2px dashed #b0d1ff;
    }

    .step_number {
        position: absolute;
        left: 4px;
        top: 0;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2f7eed;
        border: 3px solid #b0d1ff;
        color: #fff;
        z-index: 1;
        font-size: 9px;
        transition: all 0.3s ease-in-out;
    }

    .track_list li .step_name.active h3 {
        font-size: 15px;
        line-height: 20px;
    }

    .track_list li .step_name.active .step_number {
        font-size: 13px;
        left: 0;
        width: 30px;
        height: 30px;
    }

    .time_listing {
        display: flex;
        flex-wrap: wrap;
        margin: 10px -15px 20px -15px;
    }

    .time_listing li {
        padding: 0 15px;
    }
    .time_listing li:not(:last-child){
        border-right: 1px solid #d9d9d9;
    }

    .time_listing li .checkbox {
        display: block;
    }

    .selection_box {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .time_listing li .checkbox input[type=radio]:checked~.selection_box {
        color: #2f7eed;
    }

    .day_type {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
    }

    .day_type li {
        padding: 10px;
        flex: 1 0 50%;
    }

    .day_type li .day_wpr {
        display: flex;
        background: #f7f7f7;
        border-radius: 5px;
        padding: 10px 15px;
    }

    .day_type li .day_wpr label {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px 0 0 5px;
    }

    .day_type li .day_wpr label span {
        width: 20px;
        height: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        border-radius: 3px;
        overflow: hidden;
    }

    .day_type li .day_wpr label span i {
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .day_type li .day_wpr label input[type=radio]:checked~span i {
        transform: scale(1);
    }

    .day_type li .day_wpr h4 {
        min-height: 40px;
        font-size: 13px;
        line-height: 16px;
        padding: 7px 15px;
        font-weight: 500;
        display: flex;
        align-items: center;
    }

    .sqr_input {
        width: 35px;
        height: 25px;
        border: 1px solid #e9e9e9;
        margin: 0 5px;
        text-align: center;
        border-radius: 3px;
        background: #fff;
        color: #121525;
        font-size: 14px;
        font-weight: 500;
    }

    .date_input {
        width: 140px;
        height: 25px;
        border: 1px solid #d9d9d9;
        margin: 0 5px;
        padding: 0 5px;
        text-align: left;
        border-radius: 3px;
        background: #f5f5f5;
        color: #121525;
        font-size: 13px;
        font-weight: 500;
    }

    .multiselect.small_box {
        width: 110px;
        min-height: 25px;
        margin: 0 7px;
        font-size: 13px;
    }

    .sqr_input::-webkit-outer-spin-button,
    .sqr_input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .sqr_input[type=number] {
        -moz-appearance: textfield;
    }

    .time_selection {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
    }

    .time_selection .form_grp {
        width: 50%;
        align-items: flex-end;
    }
    .time_selection .form_grp .group_item{
        padding: 0 10px;
    }

    .day_sorting {
        display: flex;
        flex-wrap: wrap;
        margin: 10px -5px;
    }

    .day_sorting li {
        width: 25%;
        min-width: 90px;
        padding: 5px;
    }

    .day_sorting li label {
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 15px;
    }

    .circumference_list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px 15px -10px;
    }

    .circumference_list li {
        padding: 10px;
        width: 33.333%;
    }

    .circumference_list li label {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        padding: 15px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .circumference_list li label:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    :deep(.custom_list li) {
        border: 1px solid #eaeaea;
        border-radius: 6px;
        margin-bottom: 30px;
    }

    :deep(.custom_list li h5.category_bar) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-weight: 500;
        background: #fff;
        border-radius: 6px;
        padding: 0 15px;
        column-gap: 15px;
    }

    :deep(.custom_list li h5 label) {
        padding: 0 15px 0 0;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
        cursor: auto;
        border-right: 1px solid #d9d9d9;
    }
    :deep(.custom_list li h5 label.has){
        color: #2f7eed;
    }
    .category_bar input{
        width:100%;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        margin-right: 12px;
        padding: 15px 0;
    }
    .category_bar.active input{
        background: #f7faff;
        border-left: 1px solid #e9e9e9;
        border-right: 1px solid #e9e9e9;
        padding: 15px;
    }
    .category_bar .add_btn{
        font-size: 11px;
        line-height: 16px;
        margin: 0 0 0 15px;
        padding-right: 15px;
        border-right: 1px solid #d9d9d9;
    }
    .category_bar .add_btn i{
        font-size: 10px !important;
    }
    .category_bar .delete_btn{
        color: #eb1414;
        font-size: 11px;
        line-height: 16px;
        margin: 0 0 0 15px;
        cursor: pointer;
    }
    .category_bar .bar_text{
        display: flex;
        align-items: center;
        flex-grow: 1;
        position: relative;
    }
    .category_bar .bar_text:before{
        content: '';
        position: absolute;
        top:100%;
        left:0;
        right: 0;
        border-bottom: 1px solid #e9e9e9;
    }
    .category_bar .bar_actions{
        display: flex;
        align-items: center;
        height: 50px;
    }
    .sub_categories{
        padding: 30px 20px 0 20px;
        border-top: 1px solid #e9e9e9;
        background: #fafafa;
        border-radius: 0 0 6px 6px;
        display: none;
        flex-direction: column;
    }
    .sub_categories .habit_item{
        margin-bottom: 30px;
        border: 1px solid #e9e9e9;
        border-radius: 6px;
    }
    .sub_categories h6{
        font-size: 13px;
        line-height: 18px;
        color: #999;
        font-weight: 400;
        text-align: center;
    }
    .custom_list li.show .sub_categories{
        display: flex;
    }
    .habit_edit{
        background: #fff;
        padding: 20px 30px 0 30px;
        border-top: 1px solid #eaeaea;
        border-radius: 0 0 6px 6px;
        display: none;
    }
    .custom_list .habit_item.open .habit_edit{
        display: block;
    }
    .habit_edit .action_wpr{
        border-top: 1px solid #e9e9e9;
        margin: 30px -30px 0 -30px;
        padding: 18px 30px;
    }
    .habit_edit .action_wpr .btn{
        font-size: 11px;
        line-height: 14px;
        height: 35px;
        min-width: 90px;
        padding: 0 15px;
    }

    :deep(.custom_list li h5 span.save) {
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }

    /* :deep(.custom_list li h5 .delete_btn) {
        font-size: 16px;
        color: #eb1414;
        margin: 5px 10px;
        cursor: pointer;
    } */

    :deep(.custom_list li .section_wpr) {
        border-top: 1px solid #eaeaea;
        margin: 10px -15px 0 -15px;
        padding: 10px 30px;
    }

    .modal.add_question .modal_header {
        padding: 0 45px;
    }

    .modal.add_question .modal_body {
        padding: 30px 45px 35px 45px;
        display: block;
    }

    .mcq_list li {
        border: 1px solid #d9d9d9;
        padding: 0 15px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .mcq_list li input {
        padding: 12px 15px 10px 0;
        font-size: 14px;
        line-height: 19px;
    }

    .mcq_list li button {
        border-left: 1px solid #d9d9d9;
        padding: 0 0 0 15px;
    }

    .col-2 {
        display: flex;
        margin: 0 -15px;
    }

    .col-2 .color_palette {
        flex: 1 0 50%;
        padding: 10px 15px;
    }

    .preview_content {
        width: 40%;
        position: relative;
        margin-right: -40%;
        transition: all 0.3s ease-in-out;
        background: #fff;
        z-index: 2;
    }

    .preview_content.show {
        margin-right: 0;
    }

    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .preview_content .close_btn {
        position: absolute;
        left: -35px;
        top: 2px;
        font-size: 14px;
    }

    .preview_area .preview_area .close_btn {
        right: 15px;
        top: 15px;
        left: auto;
        position: absolute;
        background: #eaeaea;
        width: 25px;
        height: 25px;
        border-radius: 50%;
    }

    .tab_sec li {
        display: flex;
        align-items: center;
    }

    .tab_sec .tag {
        padding: 2px 5px;
        border-radius: 7px;
        font-size: 8px;
        line-height: 10px;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 5px;
    }

    .edit_section .action_wpr {
        border-top: 1px solid #e9e9e9;
        margin: 15px -20px 0 -20px;
        padding: 20px 20px 5px 20px;
    }

    .counter_fld {
        display: flex;
        flex-wrap: wrap;
        padding: 15px;
        border: 1px solid #CBCDD3;
        border-radius: 6px;
        align-items: center;
        gap: 10px;
    }

    .counter_fld i {
        width: 20px;
        height: 20px;
        background: #32373b;
        color: #fff;
        border-radius: 3px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .counter_fld p {
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
    }

    :deep(.category_preview .modal_container) {
        display: block;
        padding: 0;
    }

    :deep(.category_preview .modal_header) {
        padding: 15px 30px;
    }

    :deep(.category_preview .modal_body) {
        padding: 0 30px 30px 30px;
        flex-direction: column;
        max-height: 550px;
    }

    .cell {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        margin: 30px auto;
    }

    .cell:after,
    .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .cell .content_area {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    :deep(.cell .dashboard_footer) {
        width: 100%;
        text-align: center;
        padding: 20px 0;
    }

    :deep(.cell .dashboard_footer ul li) {
        padding: 2px;
    }

    :deep(.cell .dashboard_footer ul li a) {
        width: 20px;
        height: 20px;
        border: 1px solid #2f7eed;
        font-size: 10px;
        color: #2f7eed;
        text-decoration: none;
    }

    :deep(.cell .dashboard_footer h4) {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }

    :deep(.cell .dashboard_footer p) {
        font-size: 9px;
        line-height: 11px;
    }

    .msg_preview {
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
    }

    .sms_preview {
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        font-size: 12px;
        line-height: 17px;
        color: #5a5a5a;
    }

    .global_setting .msg_preview:after,
    .global_setting .sms_preview:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .global_setting .sms_preview:after {
        right: 30px;
        left: auto;
    }

    .msg_preview p,
    .sms_preview p {
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 400;
        margin-bottom: 10px;
    }

    .msg_preview p span {
        font-size: 14px;
        line-height: 19px;
        display: block;
        padding-bottom: 3px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msgFooter p {
        font-size: 11px;
        line-height: 17px;
    }

    .cell .msgFooter p a {
        text-decoration: none;
    }

    .sender_info {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 40px;
        /* border-bottom: 1px solid #eaeaea; */
    }

    .sender_info img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }

    .cover_type {
        margin: 15px -35px 15px -35px;
        background: #fbfbfb;
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        padding: 20px 35px;
    }

    .cover_type .type_list {
        justify-content: space-around;
        margin-top: 15px;
    }

    .que_ans {
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        margin-top: 20px;
    }

    .que_ans>h4 {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        padding: 10px 20px;
        font-weight: 500;
        background: #e9e9e9;
    }

    .ques_details {
        padding: 0 20px 20px 20px;
    }
    .days{
        display: flex;
        padding: 15px 0;
        gap: 7px;
    }
    .cell .days{
        border-top: 1px solid #E6E6E6;
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0 20px;
    }
    .days li{
        padding: 4px 0;
        flex: 0 1 55px;
    }
    .days li label{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin: 0;
        padding: 10px 6px 12px 6px;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
    }
    .days li label span{
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
    }
    .days li label .tick_box{
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #5a5a5a;
        position: relative;
        margin-top: 10px;
    }
    .days li label .tick_box:after{
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        background-color: #5a5a5a;
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }
    .days li label input{
        margin-right: 7px;
        opacity: 0;
    }
    .days li label.active{
        background: rgba(47, 126, 237, 0.05);
        border: 1px solid #b0d1ff;
    }
    .days li label.active .tick_box:after{
        transform: scale(1);
    }

    .upload_image label {
        border-radius: 5px;
    }

    .upload_image label img {
        max-height: 70px;
        width: auto;
    }

    .grp_wpr {
        display: flex;
        margin: 0 -10px;
    }

    .box_item {
        flex: 1 0 50%;
        padding: 0 10px;
    }

    .grp_wpr label {
        border: 1px solid #CBCDD3;
        border-radius: 6px;
        padding: 7px 15px;
        display: flex;
        align-items: center;
    }

    .grp_wpr label p {
        font-size: 13px;
        line-height: 15px;
    }

    .flex.space-between input {
        background: inherit;
        font-weight: bold;
        color: #2c3e50;
        width: calc(100% - 30px);
    }

    .category-error {
        margin-bottom: 15px;
        margin-top: -15px;
    }
    .tab_slider{
        margin: 0 -15px;
    }
    :deep(.dp__main .dp__input){
        font-size: 11px;
        max-width: 130px;
        padding: 0 0 0 35px !important;
        margin-left: 7px;
    }
    .info_bar {
        display: flex;
        flex-direction: column;
        /* background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 10px; */
        max-width: 400px;
        margin: 0 auto;
        position: relative;
    }
    .time {
        min-width: 150px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        padding: 10px 25px;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .time span {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        display: block;
    }
    .step_bar2 {
        position: relative;
        padding: 20px 30px 10px 30px;
    }
    .step_bar2 ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 25px;
        position: relative;
        z-index: 1;
    }

    .step_bar2 ul li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .step_bar2 ul li h6 {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        white-space: nowrap;
        text-align: center;
    }

    .step_bar2 ul li:first-child h6 {
        left: 0;
        transform: none;
        text-align: left;
    }

    .step_bar2 ul li:last-child h6 {
        right: 0;
        left: auto;
        transform: none;
        text-align: right;
    }

    .step_bar2 ul li span {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #fff;
        border: 3px solid #e5e5e5;
        font-size: 12px;
        font-weight: 500;
        color: #2c3e50;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .step_bar2 .total_bar {
        position: absolute;
        content: '';
        top: 32px;
        left: 30px;
        right: 30px;
        height: 2px;
        background: #d9d9d9;
    }

    .step_bar2 .total_bar span {
        background: #a1c8ff;
        position: absolute;
        left: 0;
        height: 100%;
        transition: all 0.3s ease-in-out;
    }

    .step_bar2 ul li.active span {
        background: #2f7eed;
        color: #fff;
        border-color: #a1c8ff;
    }
    .reset-default {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        line-height: 15px;
        color: #747474;
        font-weight: 400;
        cursor: pointer;
        position: relative;
    }
    .flex-center{
        display: flex;
        align-items:center;
    }

    /* toggle_section */
    .toggle_section{
        width: 25px;
        height: 25px;
        font-size: 16px;
        color: #999;
        cursor: pointer;
        margin-left: 15px;
    }
    .toggle_section i{
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
    }

    .global_setting .tabs_content .section_content{
        position: relative;
    }
    .global_setting .tabs_content .section_content .section_header{
        margin: 10px 0;
    }
    .global_setting .tabs_content .section_content:after{
        content: '';
        position: absolute;
        left: 20px;
        top: 100%;
        right:20px;
        border-bottom: 1px solid #e9e9e9;
    }
    .global_setting .tabs_content .section_content.show:after, .global_setting .tabs_content .section_content:last-of-type:after, .global_setting .tabs_content .section_content.open_area:after{
        border: 0;
    }
    .global_setting .tabs_content .section_content .toggle_content{
        max-height: 0;
        overflow: hidden;
        animation: smoothSlidedown 0.3s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .global_setting .tabs_content .section_content.show .toggle_content{
        max-height: 1000vh;
        animation: smoothSlideup 0.8s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .global_setting .tabs_content .section_content.show .toggle_section i{
        transform: rotate(-180deg);
    }

    /* --- */

    .section_content .section_header .status{
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        margin: 0 0 0 auto;
        flex-grow: 0;
    }
    .section_content .section_header .switch_option{
        flex: 1 1 auto;
    }
    .section_header h2 span{
        font-size: 13px;
        line-height: 17px;
        color: #f2a31d;
        display: block;
        padding-top: 7px;
    }
    .btn_list{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 10px;
    }
    .btn_list li{
        font-size:13px;
        line-height: 16px;
        color: #121525;
        padding: 5px 10px;
        cursor: pointer;
    }

    @media(max-width: 1499px) {
        .time_selection .form_grp {
            flex: 0 0 100%;
            width: 100%;
        }

        .tab_sec li {
            font-size: 12px;
            line-height: 15px;
            padding: 8px 12px;
        }

        .logo_img {
            max-width: 40px;
            width: 40px;
            height: 40px;
            max-height: 40px;
            margin-left: 15px;
            border-radius: 50%;
        }

        .cell .logo_img {
            max-height: 25px;
            max-height: 25px;
            width: 25px;
            height: 25px;
        }
    }
    :deep(.cell .dashboard) {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    @media(max-width: 1199px){
        .days li label{
            padding: 8px 6px 10px 6px;
            border-radius: 3px;
        }
        .days li label span{
            font-size: 11px;
            line-height: 14px;
        }
        .info_bar{
            margin: 20px auto;
        }
        .cover_type{
            margin: 15px -25px;
        }
    }
    @media(max-width: 991px) and (min-width: 768px){
        .days{
            gap:0;
        }
    }
    @media(max-width: 991px){
        .habit_edit{
            padding: 20px 20px 0 20px;
        }
    }
    @media(max-width: 767px){
        .category_bar input{
            font-size: 13px;
        }
    }
    @media(max-width: 499px){
        .day_type li .day_wpr h4{
            font-size: 11px;
        }
        .sqr_input{
            width: 20px;
            height: 20px;
            font-size: 10px;
        }
        :deep(.dp__main .dp__input){
            font-size: 10px;
            padding: 0 0 0 25px !important;
        }
        :deep(.dp__main .dp__input_icons){
            width: 12px;
        }
        .selection_box{
            font-size: 13px;
            line-height: 18px;
        }
        .day_type li{
            flex: 0 0 100%;
        }
        .day_type li .day_wpr{
            padding: 5px 15px;
        }
        .day_type li .day_wpr label{
            flex-shrink: 0;
        }
        .cat_stat .action_wpr{
            flex-wrap: wrap;
            padding-top: 10px;
        }
        .cat_stat .action_wpr .more_btn{
            width: 100%;
            font-size: 13px;
            margin-bottom: 10px;
        }

        .custom_list li{
            padding: 15px;
        }
        .custom_list li h5 label{
            padding: 0 10px 0 0;
        }
        .category_bar .add_btn{
            margin: 0 0 0 10px;
            padding-right: 10px;
        }
        .sub_categories{
            padding: 20px 15px;
        }
        .habit_edit{
            padding: 15px 15px 0 15px;
        }
        .cover_type{
            margin: 15px -15px;
        }
    }
    /* :deep(.cell .dashboard_content) {
        min-height: calc(100% - 132px);
    } */

    :deep(li.move.show label .fa-chevron-down:before) {
        content: "\f077";
    }

    .cover-header-with-text {
        display: flex;
    }

    .cover-header-with-text .small-cover-info {
        margin: 0;
        margin-left: 10px;
    }

    .cover-header-with-text div > small {
        font-size: 12px;
    }

    .notification-warning {
        text-align: left;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        color: #F2A31D;
        margin-top: -5px;
    }
</style>